*{
    box-sizing: border-box;
}
.bg-banner_our_special {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 300px;
    /* margin-top: 30px; */
    /* width: 100%; */
}
.app_new_our_special_offers .bg-banner_our_special{
    width: 100%;
    height: 350px;
}
.app_new_our_special_offers .bg-banner-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.special-offer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding:40px;
    height: 100%;
}

.special-offer-content h2 {
    font-family: "Poppins", sans-serif;
    color: #FFF;
    font-size:28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    margin: 0px 0px 05px 0px;
}

.special-offer-content p {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
}

.special-offer-btn {
    margin-top: 20px;
}

.special-offer-btn a {
    text-decoration: none;
}
.special-offer-btn a .btn{
    border-radius: 06px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    padding: 10px 20px;
    text-transform: unset;
    display: flex;
    font-weight: 900;
}

.special-offer-btn a .btn span {
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    color: #FFF;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.special-offer-btn img {
    width: 22px;
    height: 22px;
}
.special-offer-box-content{
    max-width: 500px;
}

@media (min-width:320px) and (max-width:767px) {
    .our-special-offers-bg {
        height: unset !important;
        width: 100% !important;
        /* background-position: left !important; */
    }
    .special-offer-content {
        padding:30px 20px;
    }
    .special-offer-content h2 {
        font-size: 24px;
        line-height: 32px;
        width: auto;
        margin: 0px 0px 5px 0px;
    }
    .special-offer-content p {
        font-size: 15px;
        line-height: 25px;
        width: auto;
    }

    .special-offer-btn {
        margin-top: 10px;
    }

    .bg-banner_our_special {
        background-position: center;
    }
    .app_new_our_special_offers .bg-banner_our_special{
       width: 100%;
       height: auto;
    }
    .special-offer-box-content{
        max-width: unset;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .app_new_our_special_offers .bg-banner_our_special{
        width: 100%;
        height: auto;
     }
}