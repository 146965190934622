.sevas_checkout .check_out_1 .summary-bg{
    margin: 0px;
}
.sevas_checkout .checkout_title h2{
    margin-bottom: 20px;
}
.sevas_checkout .check_out_1{
    margin: 0px;
}
.sevas_checkout .check_out_1 .personal-info-content .add-family{
    margin: 20px 0px 0px 0px;
    padding: 15px;
}
.sevas_checkout .check_out_1 .shipping_card_box{
    margin: 20px 0px 0px 0px;
}
.sevas_checkout .check_out_1 .Product_bg{
    margin: 30px 0px 0px 0px;
}
.sevas_checkout .dakshana_bg_select{
    margin-top: 40px !important;
}
.sevas_checkout .checkbox_dakshana{
    margin-top: 10px !important;
}
.sevas_checkout .tip_amount_details_text{
    margin-bottom: 23px;
}
.sevas_checkout .ganga_jal_cost_button{
    margin: 0;
    width: 110px;
    height: 41px;
}
.sevas_checkout .mb_mycart_remove{
    display: none;
}

@media (min-width:320px) and (max-width:767px) {
.sevas_checkout .mb_mycart_remove{
    display: block !important;
    position: absolute;
    bottom: 11px;
    right: 0;
}   
.sevas_checkout .mycart_remove{
    display: none;
}   
 .sevas_checkout .mycart_bg_seva_name_list_item_1{
    position: relative;
 }
}
