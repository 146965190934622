.footer{
    background: rgb(254 231 170 / 47%);
    padding: 30px 0px 10px 0px;
    position: relative;
    overflow: hidden;
}
.footer .small_support_sm_right{
    position: absolute;
    right: 0;
    top: 0;
}
.footer .Footer_bg{
    position: absolute;
    bottom: -40px;
    right:25%;
}
.footer-main{
    margin-bottom: 40px;
    width: 100%;
}
.footer-address p{
    color: #2c0202e3 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    margin: 0 !important;
}
.footer-address p span{
    color: #A92904;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}
.footer-address h6{
    color: #2c0202e3 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    margin: 0 !important;
}
.footer-main .footer-logo img{
    width: 180px;
}
.footer-logo h2{
    color: #2c0202;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin:0px 0px 05px 0px;
}
.protect_dharma p{
    color: #2c0202e3 !important;
    font-size: 14px!important;
    font-style: normal!important;
    font-weight: 600!important;
    line-height: 22px!important;
    margin: 0!important;
}
.footer-main .services p{
    color: #A92904;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}
.footer-main .services ul{
    padding: 0;
    margin: 10px 0px 0px 0px;
}
.footer-main .services ul li{
    list-style: none;
    cursor: pointer;
}
.footer-main .services ul li button{
    font-family: "Quicksand", sans-serif;
    color: #2c0202;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-decoration: none;
    cursor: pointer;
    text-transform: unset;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}
.footer-main .services ul li:not(:last-child){
    margin-bottom:10px;
}
.footer-main .services ul li a{
    font-family: "Quicksand", sans-serif;
    color: #2c0202;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    text-decoration: none;
    cursor: pointer;
    text-transform: unset;
}
.copy-rights-content{
    position: relative;
}
.copy-rights-content::after{
    content: '';
    position: absolute;
    right: -89px;
    top: 9px;
    width: 80px;
    height: 100%;
    background-image: url('../../assets/images/icons/sbf-sm-left.svg');
    background-repeat: no-repeat;

}
.copy-rights-content::before{
    content: '';
    position: absolute;
    left: -73px;
    top: 9px;
    width: 80px;
    height: 100%;
    background-image: url('../../assets/images/icons/sbf-sm-right.svg');
    background-repeat: no-repeat;

}
.copy-rights-content p{
    color: #2c0202 !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 25px !important;
    margin: 0 !important;
    text-align: center !important;
}
.social-icons{
    margin-top: 10px;
}
.social-icons ul{
    display: flex;
    align-items: center;
    margin-top: 0px !important;
}
.social-icons ul li{
    list-style: none;
    margin-bottom: 0 !important;
}
.social-icons ul li:not(:first-child){
    margin-left: 20px;
}
.social-icons ul li a{
    cursor: pointer;
}
.social-icons ul li img{
    width: 24px;
}
.great-things {
    padding: 0px 0px;
}
.great-things-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.great-things-content h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin: 0px 0px 20px 0px;
    text-align: center;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.great-things-content p {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    margin: 0px 0px 0px 0px;
}
.great-things .great-things-content .banner-buttons{
    margin-top: 20px;
}
.great-things .great-things-content .banner-buttons a{
    text-decoration: none;
}
.great-things .great-things-content .banner-buttons button{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    padding: 10px 30px;
    border-radius: 80px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
}
.footer .Footer_bg img{
    height: 230px;
}
.all_copy-rights-content{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1.5px solid #DDD;
    padding-top: 10px;
    /* z-index: -99; */
    position: relative;
}
.social-icons.social-icons_1{
    display: flex;
    margin: 15px 0px 0px 0px;
}
.social-icons.social-icons_1 ul{
    margin: 0 !important;
    padding: 0 !important;
}
.quick_link_main{
    display: flex;
    justify-content: center;
}
.email_phone{
    margin-top: 10px;
}
.email_phone ul{
    padding: 0;
    margin: 0;
}
.email_phone ul li{
    list-style: none;
}
.email_phone ul li:not(:first-child){
    margin-top: 10px;
}
.email_phone ul li a{
    display: flex;
    align-items: center;
    color: #2c0202e3 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    text-decoration: none;
}
.email_phone ul li a span{
    font-weight: 600 !important;
    margin-left: 3px;
}
.email_phone ul li a img{
    width: 20px;
    margin-right: 10px;
}
/* .donate_now a button */

.email_phone ul li p{
    display: flex;
    align-items: center;
    color: #2c0202e3 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    text-decoration: none;
}
.email_phone ul li p span{
    font-weight: 600 !important;
    margin-left: 3px;
}
.email_phone ul li p img{
    width: 20px;
    margin-right: 10px;
}
.timings_weeks{
    display: flex;
    flex-direction: column;
}
 

@media (min-width:320px) and (max-width:767px) {

    .footer-main .footer-logo .logo {
        font-size: 20px !important;
        line-height: 30px !important;
        margin-bottom: 10px !important;
    }

    .footer-logo h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .copy-rights-content {
        margin: 0;
    }

    .footer .Footer_bg img {
        width: 0;
    }
    .footer{
        padding-bottom: 10px;
    }

    .footer-main {
        margin: 0px 0px 0px 0px;
    }
    .footer-main .services ul li:not(:last-child){
        margin-bottom: 10px;
    }
    .quick_link_main{
        display: flex;
        justify-content: flex-start;
    }
    .social-icons.social-icons_1{
        margin: 15px 0px;
    }
    .copy-rights-content::after{
        display: none;
    }
    .copy-rights-content::before{
       display: none;
    
    }
    .footer .small_support_sm_right{
        display: none;
    }
}
@media (min-width:768px) and (max-width:900px) {
.footer .Footer_bg {
    right: -60px;
}
.footer .Footer_bg img{
    height: 230px;
}
.footer-main {
    margin: 0;
}
.footer-logo h2{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 5px;
}
.footer-main .services ul li{
    margin-bottom: 10px;
}
.quick_link_main{
    display: flex;
    justify-content: flex-start;
}
.social-icons.social-icons_1{
    margin: 15px 0px;
}
}
@media (min-width:901px) and (max-width:1199px) {
    .footer .Footer_bg img{
        height: 200px;
    }
    .footer .Footer_bg{
        right: 40%;
    }
}