.small_support_large_box_image img{
    width: 150px;
    height: 150px;
    object-fit: fill;
    border-radius: 10px;
}
.small_support_large_box_image{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.small_support_large {
    background: linear-gradient(4deg, #FDE59D 0%, #ffecb72b 49%, #fcf4ddfa 100%);
    margin-top: -42px;
}
.small_support_large_main{
    position: relative;
}
.small_support_large_main .small_support_sm_left{
    position: absolute;
    left: 0;
    top: 130px;
}
.small_support_large_main .small_support_sm_right{
    position: absolute;
    right: 0;
    top: 130px;
}
.main_smaill_box {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}
.main-box {
    border-radius: 10px;
    background: #FFF6B5;
    padding: 20px;
    position: relative;
    width: 700px;
}
.main-box .bg {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
.main-box .bg img {
    width: 100%;
    height: 100%;
}
.small-support-box {
    display: flex;
    align-items: flex-start;
}
/* .small_support-main {
    margin-top: -130px;
} */
.small-support-box .small_support_large_box_content {
    margin-left: 20px;
}
.small-support-box .small_support_large_box_content h3 {
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
}
.small-support-box .small_support_large_box_content p {
    color: #2c0202e3 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0;
}
.donate_now {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.donate_now a{
    width: 100%;
    max-width: 70%;
}
.donate_now a button {
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border-radius: 6px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
    width: 100%;
    padding: 10px 0px;
}
.tip_amount_details{
    margin-top: 10px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
}
.tip_amount_details p{
    margin: 10px 0px 0px 0px;
}
.tip_amount_details_text{
     font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    padding-left: 15px;
}
.dakshana-select{
    margin-bottom: 0px !important;
    /* margin-top: 0px !important; */
}
.dakshana_check_select{
    margin: 0px 0px 0px 10px !important;
}
.dakshana_check_select .MuiCheckbox-root{
    padding: 0px !important;
}
.donate_now_sm{
    display: none;
}
.donate_now_lg{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.donate_now_lg a button{
    width: unset;
    padding: 10px 40px;
}
.main-box .swiper-horizontal{
    padding-bottom: 0 !important;
}
.dharmashala_bg_left_image{
    position: absolute;
    left: 0;
    top: 0;
}
.dharmashala_bg_right_image{
    position: absolute;
    right: 0;
    top: 0;
}





@media (min-width:320px) and (max-width:767px) {
    /* .small_support_large_box_image{
        margin-bottom: 20px;
    } */
    .small_support-main{
        padding-top: 45px;
        margin-top: 0;
    }
    .small_support_large_box_image img{
        width: 120px;
        height: 120px;
    }
    .small-support-box {
        display: flex;
    }
    .small-support-box .small_support_large_box_content {
        margin-left: 20px !important;
    }
    .small-support-box .small_support_large_box_content h3 {
        text-align: left;
        font-size: 13px;
        line-height:15px;
    }
    .small-support-box .small_support_large_box_content p {
        text-align: left;
        font-size: 12px;
        line-height: 17px;
    }
    .small-support-box .donate_now {
        text-align: center;
    }
    .donate_now_sm{
        display: block;
    }
    .donate_now_lg{
       display: none;
    }
    .tip_amount_details {
        font-size: 12px;
        line-height: 16px;
    }   
}
@media (min-width:320px) and (max-width:345px) {
    .app_title_logo .logo{
        width: 120px !important;
    }
}
@media (min-width:350px) and (max-width:767px) {
    .small_support-main{
        padding-top: 60px;
        margin-top: 0;
    }
}
/* @media (min-width:320px) and (max-width:340px) {
    .main-box .swiper-wrapper{
        height: 250px !important;
    }
    .main-box .main_smaill_box{
        margin-bottom: 10px;
    }
}
@media (min-width:341px) and (max-width:370px) {
    .main-box .swiper-wrapper{
        height: 230px !important;
    }
    .main-box .main_smaill_box{
        margin-bottom: 20px;
    }
}
@media (min-width:371px) and (max-width:382px) {
    .main-box .swiper-wrapper{
        height: 200px !important;
    }
    .main-box .main_smaill_box{
        margin-bottom: 10px;
    }
    
}
@media (min-width:383px) and (max-width:400px) {
    .main-box .swiper-wrapper{
        height: 210px !important;
    }
    .main-box .main_smaill_box{
        margin-bottom: 10px;
    }
    
} */

@media (min-width:320px) and (max-width:767px) {
    .small_support-main .swiper {
        width: auto;
    }

    .main-box {
        width: 100% !important;
    }

    .main_smaill_box {
        display: unset !important;
        justify-content: unset !important;
    }

    .main-box .bg {
        display: none;
    }
    .small_support_large_main .small_support_sm_left{
       display: none;
    }
    .small_support_large_main .small_support_sm_right{
       display: none;
    }
}

@media (min-width:768px) and (max-width:990px) {
    .small_support-main .swiper {
        width: 100%;
    }
    .main_smaill_box {
        display: flex;
        justify-content: center;
    }
    .small-support-box .small_support_large_box_content h3 {
        font-size: 18px;
        line-height: 28px;
    }
    .small-support-box .small_support_large_box_content h3 {
        text-align: left;
        font-size: 13px;
        line-height:15px;
    }
    .small-support-box .small_support_large_box_content p {
        text-align: left;
        font-size: 12px;
        line-height: 15px;
    }
}
@media (min-width:991px) and (max-width:1024px) {
    .small-support-box {
        display: flex;
        justify-content: flex-start;
    }
}

/* @media (min-width:320px) and (max-width:399px) {

 .small_support_overlap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .small_support_large_box_content {
        margin: 20px 0px 0px 0px;
    }

    .small_support_large_box_image {
        width: 100%;
    }


} */

/* @media (min-width:400px) and (max-width:767px) {

     .small_support_large_box {
        width: 100%;
    }

    .small_support_overlap {
        display: flex;
        align-items: flex-start;
    }

    .small_support_overlap {
        display: flex;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    } 

} */