.campaigner_card_main{
    margin-top: 50px;
}
.campaigner_box{
    border-radius: 10px;
    background: rgb(254 231 170 / 47%);
    padding: 15px;
    cursor: pointer;
    height: 100%;
    width: 33.3%;
}
.campaigner_box:hover{
    box-shadow: 23.731px 28.477px 40.343px 0px rgba(0, 0, 0, 0.05);
}
.campaigner_box h3{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 0px 0;
    min-height: 56px;
}
.campaigner_box p{
    color: #2c0202e3 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
}
.donate_btn .btn{
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    border-radius: 06px;
    background: linear-gradient(90deg, rgba(200,66,4,1) 28%, rgba(244,144,4,1) 105.28%) !important;
    padding: 10px 30px;
    text-transform: unset;
    width: 100%;
    /* margin-top: 15px; */
}
.donate_btn .btn img{
    margin-left: 10px;
}
.campaigner_card_content{
    display: flex;
}
.campaigner_card_content .campaigner_box:not(:first-child){
    margin-left: 20px;
}
.campaigner_box:hover .donate_btn .MuiButtonBase-root.btn{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
    opacity: unset;
    mix-blend-mode: unset;
}
.home_puja_forms-content .personal-info{
    border: unset;
}
.campaigner-modal .MuiPaper-root{
    background-image: url('../../assets/images/login-pop-up/login_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.puja_homam_button_form .InputGroup {
    display: flex;
    width: 400px;
}
.puja_homam_button_form input[type="radio"] {
     visibility: hidden;
     height: 0; 
     width: 0;
}
.puja_homam_button_form label {
    display: flex;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    padding: 10px 10px 10px 25px;
    border-radius: 6px;
    user-select: none;
    margin-right: 8px;
    border-radius: 13px !important;
    background-color: #ffffff;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.puja_homam_button_form .puja_button{
    position: relative;
    width: 90px;
    height: 25px;
    background-color: #FFF;
    border:1px solid #F49004;
}
/* .puja_homam_button_form .puja_button::before{
    content: '';
    position: absolute;
    background-image: url('../../assets/images/icons/white-puja.png');
    width: 40px;
    height: 40px;
    background-position: center;
    background-size: cover;
    bottom: 0;
    right: 0;
} */
.puja_homam_button_form label:last-of-type {
    margin-right: 0;
}
.puja_homam_button_form input[type="radio"]:checked + label {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
    color: #FFF;
    -webkit-text-fill-color:unset;
    border: #F49004;

}
.puja_homam_button_form input[type="radio"]:hover:not(:checked) + label {   
    background: linear-gradient(276.27deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: #F49004;
    -webkit-text-fill-color:unset;
    color: #FFF;
}
.puja_homam_button_form .puja_button2{
    position: relative;
    width: 90px;
    height: 25px;
    background-color: #FFF;
    border:1px solid #F49004;
}
/* xx` */
.puja_homa_submit_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.puja_homa_submit_button .btn{
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    border-radius: 06px;
    /* opacity: 0.3; */
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    /* mix-blend-mode: luminosity; */
    text-transform: unset;
    padding: 8px 40px;

}
.puja_homa_submit_button .btn:hover{
    color: #FFF;    
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    border-radius: 06px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    mix-blend-mode:unset;
    text-transform: unset;
    padding: 8px 40px;
}
.puja_homa_submit_button:hover .MuiButtonBase-root.btn{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
    opacity: unset;
    mix-blend-mode: unset;
}
.homam_forms{
    margin-top: 50px;
}
.homam_forms_md{
    margin-top: 30px !important;
}
.homam_forms .personal-info_md{
    margin-top: 20px !important;
}
.upload_doc{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.upload_doc .btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed hsla(0, 0%, 0%, 0.4);
    background-color: #FFF;
    padding: 50px 0px;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    color: hsla(0, 0%, 0%, 1);
    width: 100%;
    max-width: 70%;
    border-radius: 10px;
}
.upload_doc .btn:hover{
    background-color: #FFF;
}
.upload_doc .btn .image{
    margin-bottom: 20px;
}
.upload_doc .btn .text_button{
    font-family: "Quicksand", sans-serif;
    font-size: 8px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 8px;
}
.add_commitee_member{
    display: flex;
}
.add_commitee_member .btn{
    padding: 30px 0px;
}
.add_commitee_member .btn1{
    margin-left: 20px;
}
.puja_home_title h6{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 0px 0;
}
.puja_home_title p{
    font-family: "Quicksand", sans-serif;
    color: #2c0202e3 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    margin: 0 !important;
}
@media (min-width:320px) and (max-width:767px) {
    .d-none-sm{
        display: none !important;
    }
.campaigner_card_main{
    margin-top: 30px !important;
}
.campaigner_card_content{
    display: flex;
    flex-direction: column;
}
.campaigner_card_content .campaigner_box:not(:first-child){
    margin: 20px 0px 0px 0px;
}
.campaigner_box .donate_btn .MuiButtonBase-root.btn{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
    opacity: unset;
    mix-blend-mode: unset;
    margin: 0 !important;
}
.campaigner_box{
    box-shadow: 23.731px 28.477px 40.343px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
}
.campaigner_box h3{
    min-height: 0;
    margin-bottom: 10px !important;
}
.campaigner_box p{
    font-size: 14px;
    line-height: 22px;
    min-height: 0;
    margin-bottom: 20px;
}
.puja_homam_button_form .InputGroup{
    width: auto;
}
.log_in_box_content .welcome_content h2{
    font-size: 25px !important;
    line-height: 35px !important;
}
.upload_doc .btn{
    text-align: center;
    font-size: 10px;
}
.upload_doc .btn .image{
    margin-bottom: 10px;
}
.upload_doc .btn .text_button{
    margin-top: 10px !important;
}
.add_commitee_member{
    display: flex;
    flex-direction: column;
}
.add_commitee_member .btn1{
    margin: 20px 0px 0px 0px !important;
}
.back_button{
    margin-bottom: 20px;
}
.campaigner_box{
    width: 100%;
}

.campaigner_box p {
    font-size: 14px;
    line-height: 22px;
    min-height: 0;
    margin-bottom: 20px;
}

.homam_forms_md{
    margin-top: 20px !important;
}

}

@media (min-width:768px) and (max-width:991px){
.campaigner_card_content{
    display: flex;
    flex-wrap: wrap;
}
.campaigner_card_content .campaigner_box:nth-child(2){
    margin: 0px 0px 10px 10px;
}
.campaigner_card_content .campaigner_box:nth-child(3){
    margin: 10px 05px 0px 0px;
}
.campaigner_card_content .campaigner_box:nth-child(4){
    margin: 10px 0px 0px 05px;
}
.campaigner_box p{
    min-height: 0;
}
.campaigner_box .donate_btn{
    margin-top: 20px;
}
.campaigner_box .donate_btn .MuiButtonBase-root.btn{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
    opacity: unset;
    mix-blend-mode: unset;
    margin: 0 !important;
    width: 100%;
}
.campaigner_box{
    width: 48%;
    box-shadow: 23.731px 28.477px 40.343px 0px rgba(0, 0, 0, 0.05);
}

.puja_homam_button_form .InputGroup{
    width: auto;
}
.log_in_box_content .welcome_content h2{
    font-size: 25px !important;
    line-height: 35px !important;
}

.upload_doc .btn{
    text-align: center;
}
.upload_doc .btn .image{
    margin-bottom: 10px;
}
.upload_doc .btn .text_button{
    margin-top: 10px !important;
}
.donate_btn .btn{
    width: auto;
}
.campaigner_box h3{
    min-height: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 28px;
}
.our-special-offers{
    padding: 50px 0px;
}

}
@media (min-width:992px) and (max-width:1100px) {
.campaigner_box p{
    min-height: 20px;
}
}