.terms_conditions{
    background: rgb(254 231 170 / 47%);
    padding: 160px 0px 120px 0px;
}
.terms_conditions_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.terms_conditions_title h2{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    margin: 0;
}
.terms_conditions_content_main{
    margin: 40px 0px;
}
.terms_conditions_content h3{
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0 0 05px 0;
}
.terms_conditions_content p{
    color: rgba(37, 37, 37, 0.60);;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0;
}
.terms_conditions_content span{
    color: #2c0202;
}
.terms_conditions_mini_content{
    margin-top: 20px;
}
.terms_conditions_mini_content h3,
.terms_conditions_content_2 h3{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0px 0px 05px 0px;
}
.terms_conditions_mini_content p{
    font-family: "Quicksand", sans-serif;
    color: rgba(37, 37, 37, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0px 10px 0px;
}
.terms_conditions_content_2 p{
    font-family: "Quicksand", sans-serif;
    color: rgba(37, 37, 37, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0px 05px 0px;
}
.terms_conditions_mini_content p span{
    color: #2c0202;
    font-weight: 800;
}
.terms_conditions_mini_content p a{
    color: #A92904;
    text-decoration: none;
    font-weight: 600;
}
.terms_conditions_mini_content p strong{
    color: #2c0202;
    font-weight: 600;
}
.terms_conditions_mini_content p .services{
    color: #A92904;
    font-weight: 600;
}
@media (min-width:320px) and (max-width:991px) {
.terms_conditions{
    padding: 120px 0px 60px 0px;
}
.terms_conditions_title h2{
    font-size: 24px;
    line-height: 34px;
}
.terms_conditions_content h3{
    font-size: 20px;
    line-height: 24px;
}
.terms_conditions_mini_content{
    margin-top: 10px;
}
.terms_conditions_mini_content h3{
    font-size: 18px;
    line-height: 24px;
}
}