.MuiBox-root.css-1ia3l51 {
    border-radius: 10px;
    background-image: url('../../assets/images/login-pop-up/login_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(60px);
    padding: 50px;
    position: relative;
}

.MuiBox-root.css-1ia3l51 .back_button {
    content: '';
    position: absolute;
    left: 10px;
    top: 20px;
}

.log_in_box_content .welcome_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.log_in_box_content .welcome_content h2 {
    color: #2c0202;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'salt' on, 'liga' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 28px;
    margin: 0px 0px 04px 0px;
}
.log_in_box_content .welcome_content p {
    color: #A1A1A1;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    margin: 0;
}

.log_in_box_content .welcome_content p span {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
}

.mobile_no-content {
    display: flex;
    flex-direction: column;
}

.mobile_no-content .mobile_number {
    display: flex;
    justify-content: center;
}

.mobile_number .input_label {
    width: 20px;
    border-radius: unset;
}

.mobile_no-content label {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0px 0px 0px 0px;
}

.mobile_no-content input {
    border-radius: 06px;
    border: 1px solid rgb(120 118 118 / 80%);
    background: #FFF;
    height: 40px;
    padding: 0px 10px;
    color: rgba(0, 0, 0, 0.40);
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    width: 230px;
}
.mobile_no-content input:not(:first-child){
    padding: 0 0 0 10px;
}
.mobile_no-content .input_label {
    width: 50px;
    border-radius: 06px;
}
.mobile_no-content input:focus {
    outline: unset;
}
.mobile_no-content span {
    display: flex;
    align-items: start;
    margin-top: 12px;
    color: #A1A1A1;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.mobile_no-content span img {
    margin-right: 4px;
}
.login_btn {
    margin-top: 20px;
}
.login_btn button {
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    border-radius: 06px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
    padding: 10px 30px;
}
.send-otp-number {
    margin-top: 20px;
}

.send-otp-number input {
    width: 44px !important;
    height: 60px;
    border-radius: 15px;
    border: 0.6px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    position: relative;
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
}

.send-otp-number inpuT:focus {
    outline: unset;
}

.send-otp-number input:not(:first-child) {
    margin-left: 10px;
}

.send-otp-number span {
    font-size: 0;
}

.resending-otp {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.resending-otp p {
    color: #A1A1A1 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    margin: 0 !important;
}

.resending-otp p span {
    color: #2c0202;
}

.resending-otp button {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: none;
    margin-top: 05px;
    padding: 0;
}

.otp-resend {
    display: flex;
    align-items: center;
    margin-top: 0px;
}

.otp-resend p {
    color: #A1A1A1 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    margin: 0;
}

.otp-resend button {
    margin-left: 05px;
    padding: 0;
}

.otp-resend button img {
    margin-right: 5px;
}

.otp-resend button {
    color: #A1A1A1;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-transform: none;
}

.verify_btn {
    margin-top: 20px;
}

.verify_btn button {
    padding: 10px 40px;
}
/* end */
/* styles start */
.Seva-Booking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px !important;
}

.Seva-Booking .Seva-Booking-text {
    margin: 10px 0px 20px 0px;
}

.Seva-Booking .Seva-Booking-text h5 {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
    text-align: center;
}

.Seva-Booking .Seva-Booking-text p {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center
}

.Seva-Booking h6 {
    color: rgba(37, 37, 37, 0.80);
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 579px;
    margin-bottom: 45px;
}

.Seva-Booking .MuiButton-root {
    border-radius: 80px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    width: 264px;
    height: 52px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.Seva-Booking .MuiButton-root .MuiSvgIcon-root {
    margin-left: 15px;
    font-size: 20px;
}

@media (min-width:320px) and (max-width:767px) {
    .mobile_no-content input {
        width: 140px;
    }
    .mobile_no-content span img {
        margin-right: 0;
        width: 20px;
    }
    #demo-customized-button {
        padding: 0;
    }
    .mobile_no-content{
        margin-top: 10px;
    }
    .login_main_popup .log_in_box_content .welcome_content h2{
        font-size: 20px !important;
        line-height:28px !important;
    }
}
@media (min-width:320px) and (max-width:375px) {
    .otp-resend{
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .otp-resend button {
        margin-top: 5px;
        margin-left: 0px;
        padding: 0;
    }
}
@media (min-width:320px) and (max-width:392px) {
    .login_modal .MuiBox-root{
        width: 311px;
        padding: 30px;
    }
}
@media (min-width:393px) and (max-width:500px) {
    .login_modal .MuiBox-root{
        width: 376px;
        padding: 32px;
    }
}