.app_header {
    width: 100%;
    height: 600px;
    background-image: url('../../../public/assets/images/banner/new-bg-11.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    position: relative;
}
.app_title {
    padding: 10px 0px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    left: 0;
}
.app_title_logo .logo{
    width: 180px;
}

/* .app_title{
    background: rgba(253, 253, 253, 0.07);
    margin-top: 10px;
    padding: 10px 40px;
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0%;
    z-index: 99999999999;
    width: 100%;
    border-radius: unset;
} */
.app_title ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app_title ul li {
    list-style: none;
}

.app_title ul .app_title_logo a {
    color: #000;
    font-family: 'Mulish', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-decoration: none;
    display: flex;
}

.app_title .app_title_list a {
    text-decoration: none;
}

.app_title .app_title_list .MuiButton-root.btn{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding: 10px 30px;
    border-radius: 06px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
}
#demo-customized-button{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 0px;
    font-style: normal;
    font-weight: 700;
    line-height: 0px;
    padding: 10px 30px;
    border-radius: 80px;
    text-transform: unset;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app_title .app_title_list .btn span {
    color: #FFF !important;
    font-weight: 700;
}

.app_title .app_title_list .btn img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.app_title ul li .MuiButton-root:hover {
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    padding: 10px 30px;
    border-radius: 80px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
}

.app_title ul li .MuiButton-startIcon {
    margin-right: 7px;
    margin-bottom: 3px;

}

.app_title ul li .MuiButton-startIcon img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.app_title ul li .MuiButton-endIcon {
    margin-left: 8px;
}

.app_title ul li .MuiButton-endIcon .rotate-icon {
    transition: transform 0.2s ease-in-out;
}

.app_title ul li .MuiButton-endIcon .rotate-icon.rotate-180 {
    transform: rotate(180deg);
}

.app_title .muimenu-paper {
    background-color: white !important;
    color: #0F4D81 !important;
}

#demo-customized-menu {
    left: 0px;
}

#demo-customized-menu .MuiPaper-elevation8 {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, .15);
}

/* #demo-customized-menu .MuiPaper-root {
    width: 140px !important;
    left: 1190px !important;
}

#demo-customized-menu .MuiPaper-root .MuiList-root {
    padding: 15px 0px 0px 0px !important;
    left: 1110px !important;
} */

#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: "Quicksand", sans-serif;
    line-height: normal;
    font-style: normal;
    font-weight: 600;
    color: #313131;
}

#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root img {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    object-fit: contain;
    filter: grayscale(1);
    margin-bottom: 3px;
}

#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root:hover {
    color: #E25704;
}

#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root:hover img {
    filter: unset;
}
.main_list_items{
    display: flex;
    align-items: center;
}
.main_list_items li:not(:last-child){
    margin-right: 20px;
}
.main_list_items li:nth-child(5){
    margin-right: 0px;
}
.main_list_items li a{
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    color: #2c0202;
    font-size: 15px;
    line-height: 25px;
    font-weight: 600;
}
.main_list_items li a:hover{
    color: #c84204;
}
#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons{
    display: none;
}
.main_list_items li{
    display: block;
}
#demo-customized-menu .MuiPaper-root .MuiList-root a{
    text-decoration: none;
}
#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons span{
    color: #2c0202;
}
.app_title_list .btn{
    margin-left: 15px;
}
#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mob_design{
}
#demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.lap_design{
    display: none;
    
}
@media (min-width:320px) and (max-width:767px) {

    .app_header {
        background-image: url('../../../public/assets/images/banner/banner.png');
        height: unset;
        padding: 120px 0px;
    }
    .app_title .app_title_list .MuiButton-root.btn{
        padding: 06px 20px;
        font-size: 14px;
    }
    #demo-customized-button{
        padding: 0;
        font-size: 0;
    }
    #demo-customized-button .customer_user_name{
        display: none !important;
    }
    /* #demo-customized-button .customer_user_name{
        display: none !important;
    } */
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons{
        display: block;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons img{
        position: relative;
        top: 8px;
        margin-right: 5px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root img{
        margin-right: 6px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons span{
        font-size: 12px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root{
        font-size: 12px !important;
        margin: 0 0 8px 0;
        padding: 0px 10px !important;
        min-height: 0 !important;
    }
    .main_list_items li{
        display: none;
    }
    .main_list_items li:last-child{
        display: block;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mob_design{
        display: block;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mob_design img{
        position: relative;
        top: 6px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.lap_design{
        display: none;
    }
}
@media (min-width:768px) and (max-width:900px) {
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons{
        display: block;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons img{
        position: relative;
        top: 9px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mb_icons span{
        font-size: 12px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root{
        font-size: 12px !important;
        margin: 0 0 8px 0;
        padding: 0px 10px !important;
        min-height: 0 !important;
    }
    .main_list_items li{
        display: none;
    }
    .main_list_items li:last-child{
        display: block;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mob_design{
        display: block;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.mob_design img{
        position: relative;
        top: 6px;
    }
    #demo-customized-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root.lap_design{
        display: none;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .app_header {
    width: 100%;
    height: 400px; 
    background-image: url('../../../public/assets/images/banner/banner.png');
}
}
@media (min-width:992px) and (max-width:1200px) {
    .app_header {
    width: 100%;
    height: 400px;
    margin-top: 35px;
}
}
@media (min-width:1201px) and (max-width:1299px) {
    .app_header {
    width: 100%;
    height: 470px;
}
}
@media (min-width:1301px) and (max-width:1399px) {
    .app_header {
    width: 100%;
    height: 500px;
}
}