.error_main{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error_main .error_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error_main .error_content h2{
    -webkit-text-fill-color: #0000;
    background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 120px;
    margin: 0 0 2px 0;
}
.error_main .error_content h3{
    margin: 10px 0px;
}
.error_main .error_content p{
    margin: 10px 0px;
    width: 750px;
    text-align: center;   
    font-size: 16px;
    line-height: 26px;
}
.error_main .error_content p a{
    margin-left: 8px;
    -webkit-text-fill-color: #0000;
    background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    border-bottom: 1.5px dashed #e25704;
    font-weight: 600;
    text-decoration: none;
}
@media (min-width:320px) and (max-width:767px) {
    .error_main .error_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .error_main .error_content h2{
        font-size: 90px;
        font-style: normal;
        font-weight: 700;
        line-height: 120px;
        margin: 0 0 2px 0;
    }
    .error_main .error_content h3{
        margin: 10px 0px;
    }
    .error_main .error_content p{
        width: auto;
    }
    .error_main .error_content p a{
        margin-left: 8px;
        -webkit-text-fill-color: #0000;
        background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
        background-clip: text;
        -webkit-background-clip: text;
        border-bottom: 1.5px dashed #e25704;
        font-weight: 600;
        text-decoration: none;
    }
}