.details-page-2 {
  margin-top: -503px;
  position: sticky;
  top: -68px;
  left: 0;
}
.new_foundation_construct {
  display: flex;
  justify-content: flex-start;
}
.new_foundation_construct_bg {
  border-radius: 22px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  box-shadow: 23.731px 28.477px 40.343px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-left: 20px;
}
.new_foundation_construct_bg h3 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  color: #2c0202;
}
.new_foundation_construct_bg ul {
  margin: 0;
  padding: 5px 0px 10px 20px;
}

.new_foundation_construct_bg ul li {
  position: relative;
}

.new_foundation_construct_bg .date_event_location li {
  list-style: none;
  /* margin-bottom: 6px; */
  color: #2c0202e3 !important;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.new_foundation_construct_bg .date_event_location li:not(:last-child) {
  margin-bottom: 06px;
}

.new_foundation_construct_bg .date_event_location li span {
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
  font-weight: 700;
  margin-left: 6px;
}

.new_foundation_construct_bg .date_event_location .first-child {
  position: relative;
}

.new_foundation_construct_bg .date_event_location .first-child::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/details_puja/icons/date.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  top: 3px;
  left: -20px;
}

.new_foundation_construct_bg .date_event_location .second-child {
  position: relative;
}

.new_foundation_construct_bg .date_event_location .second-child::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/details_puja/icons/location.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  top: 3px;
  left: -20px;
}
.new_foundation_construct_bg .date_event_location .organiser_hand::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/icons/hand.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 20px;
  height: 20px;
  top: 3px;
  left: -20px;
}
.new_foundation_construct_bg .date_event_location .third-child {
  position: relative;
}

.new_foundation_construct_bg .date_event_location .third-child::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/details_puja/icons/event.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  top: 3px;
  left: -20px;
}

.new_foundation_construct_bg .donate_bg {
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 0.8);
  background: #fff;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 10px 15px;
}

.new_foundation_construct_bg .donate_bg p {
  color: #8d8d8d;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
}

.donate_bg .product_percentage_count {
  margin-bottom: 10px;
}

.donate_bg .product_percentage h3 {
  font-size: 15px;
  line-height: 23px;
}

.donate_bg h4 {
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
}
.donate_cost_buttons ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.mostdonated{    
  display: inline-grid;
  text-align: center;
  margin-top: -10px;
}
.default-donation{
  font-size: 9px;
}
.donate_cost_buttons ul li:not(:last-child) {
  margin-right: 6px;
}

.donate_cost_buttons ul li {
  list-style: none;
}

.donate_cost_buttons ul li Button {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: unset;
  border-radius: 06px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: #fff;
  padding: 6px 10px;
  letter-spacing: 1px;
}

.donate_cost_buttons ul li Button:hover {
  background: linear-gradient(
      276deg,
      #a92902 -4.36%,
      #e25704 49.89%,
      #f49004 105.28%
    ),
    #fff;
  color: #fff;
  font-weight: 800;
}

.donate_cost_buttons ul li Button.active {
  background: linear-gradient(
      276deg,
      #a92902 -4.36%,
      #e25704 49.89%,
      #f49004 105.28%
    ),
    #fff;
  color: #fff;
  font-weight: 800;
}

.donate_bg .enter_amount {
  margin-top: -50px;
}
.donate_bg .enter_amount .MuiFormControl-root {
  margin: 8px 0;
}
.donate_bg .enter_amount .MuiOutlinedInput-root {
  padding-left: 0px !important;
  border-radius: 5px !important;
  background:#fff;
}

.donate_bg .enter_amount .MuiOutlinedInput-notchedOutline:hover {
  border-color: rgba(238, 238, 238, 0.8) !important;
}

.donate_bg .enter_amount .MuiInputLabel-root {
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.donate_bg .enter_amount .MuiInputBase-input {
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.donate_bg .MuiOutlinedInput-root .MuiInputAdornment-positionStart p {
  padding-left: 10px !important;
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.new_foundation_construct_bg .donate_now {
  margin-top: 20px;
}

.new_foundation_construct_bg .donate_now button {
  border-radius: 06px;
  background: linear-gradient(
      276deg,
      #a92902 -4.36%,
      #e25704 49.89%,
      #f49004 105.28%
    ),
    #fff;
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 10px 20px;
  text-transform: unset;
  width: 100%;
}
.new_foundation_construct_bg .donate_now button img {
  margin-left: 10px;
}
.first_top_recent_donors {
  border-top: 2px solid #ddd;
  margin-top: 40px;
  padding-top: 10px;
}
.anonymous_image img {
  width: 40px;
}
.first_top_recent_donors_list {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.anonymous {
  margin-left: 10px;
}
.anonymous h3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin: 0px 0px 3px 0px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.anonymous p {
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
  margin: 10px 0px 0px 0px;
  color: #2c0202 !important;
}
.anonymous p span {
  background-color: rgba(242, 201, 76, 0.25);
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
}
.first_top_recent_donors_list_2 .anonymous p span {
  background-color: hsl(53, 100%, 85%);
  color: #2c0202;
}
.first_top_recent_donors {
  display: block;
}
.new_foundation_construct .donate_now_sm {
  display: block;
}
.new_foundation_construct .date_event_location_sm {
  display: block;
}
/* .new_foundation_construct .date_event_location_sm{
    display: block;
} */
.outlined_amount .MuiInputLabel-animated {
  left: 0px !important;
  top: 4px !important;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0px 5px;
  border-radius: 4px;
}

.enter_more_price {
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900 !important;
  margin: 5px 0px 0px 5px !important;
  font-size: 15px;
}
.target_reached_btn{
  background: #e0e0e0 !important;
  color: #898888;
}
.target_reached_btn:hover{
  background: #e0e0e0 !important;
  color: #898888;
}

@media (min-width: 320px) and (max-width: 767px) {
  .new_foundation_construct {
    margin-top: 0px;
    /* position: absolute;
        top: 178px; */
  }
  .new_foundation_construct_bg {
    margin: 0px 0px 0px 0px;
  }
  .new_foundation_construct_bg .date_event_location li {
    font-size: 15px;
    line-height: 25px;
  }
  .donate_cost_buttons ul {
    display: flex;
    flex-wrap: wrap;
  }
  .details_donation {
    margin-bottom: 40px;
  }
  .details_donation_scroller .champaign-tab .nav button {
    white-space: normal;
  }

  .champaign-tab .nav button.active::after {
    bottom: -2px;
  }
  .new_foundation_construct_bg ul {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .details_donation_sm {
    margin-bottom: 0 !important;
  }
  .new_foundation_construct_bg .donate_bg {
    margin-top: 0px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    padding: 0;
  }
  .donate_bg .enter_amount {
    margin: 0;
  }
  .donate_bg .enter_amount .MuiFormControl-root {
    margin: 8px 0;
  }
  .product_percentage {
    margin: 10px 0px;
  }
  .donate_bg h4 {
    font-size: 13px;
    line-height: 23px;
  }
  .new_foundation_construct_bg h3 {
    font-size: 16px;
    line-height: 26px;
  }
  .donate_cost_buttons ul li button {
    font-size: 12px;
    list-style: 13px;
    padding: 5px 8px;
  }
  .details_donation_sm {
    margin-top: 0px !important;
  }
  .first_top_recent_donors {
    display: none;
  }
  .new_foundation_construct .donate_now_sm {
    display: none;
  }
  .new_foundation_construct .date_event_location_sm {
    display: none;
  }
  .outlined_amount .MuiInputLabel-animated {
    left: 0px !important;
    top: 4px !important;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 0px 5px;
    border-radius: 4px;
  }
}
@media (min-width: 320px) and (max-width: 335px) {
  .donate_cost_buttons ul li:nth-child(4),
  .donate_cost_buttons ul li:nth-child(5),
  .donate_cost_buttons ul li:nth-child(6),
  .donate_cost_buttons ul li:nth-child(7) {
    margin: 06px 0px 0px 0px !important;
  }
}
@media (min-width: 320px) and (max-width: 383px) {
  .donate_cost_buttons ul li:nth-child(5) {
    margin: 06px 0px 0px 0px !important;
  }
  .new_foundation_construct {
    margin-top: 20px;
    /* position: absolute;
        top: 178px; */
  }
}
@media (min-width: 330px) and (max-width: 767px) {
  .new_foundation_construct_bg {
    margin: 20px 0px 0px 0px !important;
  }

}
@media (min-width: 400px) and (max-width: 500px) {
  .new_foundation_construct {
    margin-top: 10px;
  }
  .details_donation_sm .details-page-1 {
    margin-top: 0px !important;
}
}
@media (min-width: 501px) and (max-width: 600px) {
  .new_foundation_construct {
    margin-top: 50px;
  }
  .details_donation_sm .details-page-1 {
    margin-top: 0px !important;
}
}
@media (min-width: 601px) and (max-width: 700px) {
  .new_foundation_construct {
    margin-top: 70px
  }
  .details_donation_sm .details-page-1 {
    margin-top: 0px !important;
}
}
@media (min-width: 701px) and (max-width: 800px) {
  .new_foundation_construct {
    margin-top: 70px;
  }
  .details_donation_sm .details-page-1 {
    margin-top: 0px !important;
}
}
@media (min-width: 801px) and (max-width: 900px) {
  .new_foundation_construct {
    margin-top: 130px;
  }
  .details_donation_sm .details-page-1 {
    margin-top: 0px !important;
}
}

@media (min-width: 767px) and (max-width: 900px) {
  .new_foundation_construct_bg {
    margin: 0px 0px 0px 0px !important;
  }
  .new_foundation_construct_bg h3 {
    font-size: 16px;
    line-height: 26px;
  }
  .new_foundation_construct_bg ul {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .details_donation_sm {
    margin-bottom: 0 !important;
  }
  .new_foundation_construct_bg .donate_bg {
    margin-top: 0px;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    padding: 0;
  }
  .new_foundation_construct_bg .date_event_location li {
    font-size: 15px;
    line-height: 25px;
  }
  .donate_bg .enter_amount {
    margin: 0;
  }
  .donate_bg .enter_amount .MuiFormControl-root {
    margin: 8px 0;
  }
  .product_percentage {
    margin: 10px 0px;
  }
  .donate_bg h4 {
    font-size: 13px;
    line-height: 23px;
  }
  /* .new_foundation_construct_bg {
        margin: 40px 0px 0px 0px !important;
    } */
  .first_top_recent_donors {
    display: none;
  }
  .new_foundation_construct .donate_now_sm {
    display: none;
  }
  .new_foundation_construct .date_event_location_sm {
    display: none;
  }
}
@media (min-width: 900px) and (max-width: 989px) {
  .donate_cost_buttons ul li:nth-child(3) {
    margin: 06px 6px 0px 0px;
  }
  .donate_cost_buttons ul li:nth-child(4) {
    margin: 06px 0px 0px 0px;
  }
  .donate_cost_buttons ul li:nth-child(5) {
    margin: 06px 0px 0px 0px;
  }
}
@media (min-width: 972px) and (max-width: 989px) {
  .donate_cost_buttons ul li:nth-child(5) {
    margin: 06px 0px 0px 06px;
  }
}
@media (min-width: 990px) and (max-width: 1199px) {
  .donate_cost_buttons ul li:nth-child(3) {
    margin: 0px 6px 0px 0px;
  }

}
@media (min-width: 990px) and (max-width: 1180px) {
  .donate_cost_buttons ul li:nth-child(4) {
    margin: 06px 0px 0px 0px;
  }
}
@media (min-width:900px) and (max-width:1200px) {
  .details-page-2 {
    margin-top: -363px;
    position: sticky;
    top: -68px;
    left: 0;
}
.donation_grid {
  max-width: 70% !important;
  -webkit-flex-basis: 68% !important;
}
}
@media (min-width:1201px) and (max-width:1400px) {
  .details-page-2 {
    margin-top: -363px;
    position: sticky;
    top: -68px;
    left: 0;
}
.donation_grid {
  max-width: 70% !important;
  -webkit-flex-basis: 59% !important;
}
}
@media (min-width:1401px) and (max-width:1499px) {
  .details-page-2 {
    margin-top: -400px;
    position: sticky;
    top: -68px;
    left: 0;
}
.donation_grid {
  max-width: 70% !important;
  -webkit-flex-basis: 59% !important;
}
}
/* @media (min-width:1401px) and (max-width:1499px) {
  .details-page-2 {
    margin-top: -400px;
    position: sticky;
    top: -68px;
    left: 0;
}
.donation_grid {
  max-width: 70% !important;
  -webkit-flex-basis: 59% !important;
}
} */