.explore_puja_banner{
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.explore_puja_banner .special_pujas_donation_title .donation_puja_buttons{
    display: flex;
    margin-bottom: 40px;
}
.explore_puja-boxs{
    margin-top: 50px;
}
@media (min-width:320px) and (max-width:400px) {
.explore_puja_banner .special_pujas_donation_title .donation_puja_buttons{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.explore_puja_banner .special_pujas_donation_title .donation_puja_buttons button:not(:last-child){
    margin-bottom: 10px;
}
}
@media (min-width:401px) and (max-width:767px) {
.explore_puja_banner .special_pujas_donation_title .donation_puja_buttons button:not(:last-child){
    margin-right: 5px;
}
}