.order_transaction .MuiFormControl-root {
    width: 100%;
}

.order_transaction .MuiInputBase-root {
    border-radius: 33px;
    border: 0.6px solid #A92902;
    background: #FFF;
    padding: 10px;
    color: rgba(0, 0, 0, 0.80);
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
}

.order_transaction .MuiInputBase-inputAdornedStart::placeholder {
    color: rgba(0, 0, 0, 0.80) !important;

}

.order_transaction .MuiInput-underline::before {
    border-bottom: unset !important;
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
    border-bottom: unset !important;
}

.no_seva_booked {
    margin-top: 20px;
}

.no_seva_booked p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin: 0;
}

.puja_name_search {
    margin-top: 40px;
}

.puja_name_search_content {
    display: flex;
    justify-content: space-between;
}

.puja_name_search_content:not(:first-child) {
    margin-top: 20px;
    border-top: 1px solid #DDD;
    padding-top: 10px;
}
.puja_name_search_content_list h4{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 5px 0px;
}

.puja_name_search_content_list h5 {
    color: rgba(44, 44, 44, 0.75);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0px 0px 5px 0px;
}

.image-container img {
    width: 130px;
}

.puja_name_search_content_list {
    margin-left: 10px;
}

.puja_name_search_content_list h6 {
    color: #2c0202e3 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    margin: 0px;
}

.puja_name_search_content_list h6 span {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.puja_name_search {
    max-height: 600px;
    overflow-y: scroll;
}
.success {
    border:1px solid #4caf50;
    color:#4caf50;
    padding:8px 20px;
    border-radius: 5px;
    text-align: center;
    font-weight: 600;
}
.failed {
    background-color: #f44336;
    color: white;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
}

.other {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    padding: 8px 20px !important;
    text-transform: unset;
    border: 1px solid #e15604;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: unset;
}

.puja_name_search_content_list p {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
}
.remove_button button{
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: unset;
    text-decoration-line: underline;
}

.puja_name_search_content_1 {
    position: relative;
    padding-top: 20px;
}

.puja_name_search_content_1::before {
    content: '';
    position: absolute;
    width: 500px;
    height: 1px;
    background-image: url('../../assets/images/profile/border.png');
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.apply_eight_modal .MuiFormControl-root{
    width: 100%;
}
.apply_eight_modal .personal-info .MuiOutlinedInput-notchedOutline{
    border-radius: 6px;
    border: 2px solid rgb(26 25 25 / 14%) !important;
    border-color: rgb(26 25 25 / 14%) !important;
}
.apply_eight_modal .MuiFormControl-root .MuiInputBase-formControl{
    height: 46px;
}
.apply_eight_modal #outlined-basic {
    color: #1E2833 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 13px;
}
.apply_eight_modal .MuiFormControl-root .MuiInputLabel-animated{
    top: 0px;
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
}
.apply_eight_modal .personal_information_form {
    margin-top: 60px;
}
.apply_eighty_g_btn.MuiButtonBase-root{
    border: unset;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #2c0202;
    margin: 2px 0 0 0;
    text-transform: unset;
    padding: 0;
}
/* .apply_eight_modal .MuiDialog-paperScrollPaper{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
}
.apply_eight_modal .MuiModal-backdrop{
    background-color: rgba(0, 0, 0, -0.88) !important;
    backdrop-filter: blur(1px) !important;
} */
.submitting_details_content .thank_you_logo{
    width: 150px;
    margin-bottom: 10px;
}
.apply_eight_g_main{
    margin-top: 10px !important;
}
.apply_eight_g_main_box h2{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.apply_eight_g_main .eight_g_submit button{
    width: 300px;
}
.apply_eight_g_main .eight_g_submit span{
    display: flex;
    justify-content: center;
}

.loader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

@media (min-width:320px) and (max-width:767px) {
    .puja_name_search_content_1::before {
        width: 200px;
    }

    .puja_name_search_content_list h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .puja_name_search_content_list h6 {
        white-space: nowrap;
    }

    .puja_name_search_content {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .puja_name_search_content:not(:first-child) {
        margin-top: 10px;
    }

    .image-container img {
        width: 100%;
    }

    .puja_name_search_content_list {
        margin: 10px 0px 0px 0px;
    }

    .puja_name_search_content_list h4 {
        font-size: 16px;
        line-height: 26px;
    }

    .puja_name_search_content_list h5 {
        font-size: 14px;
        line-height: 24px;
    }

    .puja_name_search_content_list p {
        font-size: 18px;
        line-height: 24px;
    }

    .other {
        text-align: center;
        margin-top: 10px;
    }
    .success{
        margin-top: 10px;
        text-align: center;
    }
    .apply_eight_g_main .eight_g_submit button{
        width: 250px;
    }
}

@media (min-width:767px) and (max-width:992px) {
    .puja_name_search_content_list {
        margin: 0px 0px 0px 10px;
    }

    .puja_name_search_content_list h4 {
        font-size: 16px;
        line-height: 26px;
    }

    .puja_name_search_content_list h5 {
        font-size: 14px;
        line-height: 24px;
    }

    .puja_name_search_content_list p {
        font-size: 18px;
        line-height: 24px;
    }
}

@media (min-width:900px) and (max-width:1199px) {
    .puja_name_search_content_1::before {
        width: 200px;
    }
}