/* .donation_bg {
    width: 100%;
    height: 450px;
    background-image: url('../../assets/images/details_donation/donation_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: 25px;
} */
.donation_bg_main{
    margin-top: 90px;
 }
 .donation_bg img{
    width: 100%;  
    border-radius: 8px;  
 }
 .donation_grid{
   max-width: 70% !important;
   -webkit-flex-basis: 67% !important;
 }
 
.details_donation {
    margin-top: -80px;
    margin-bottom: 10px;
}
/* .details_donation {
    margin-top: -80px;
    margin-bottom: 10px;
} */

/* styles */
.one {
    margin-top: -65px;
}

.champaign-tab .nav-1 {
    position: sticky;
    top: 90px;
    display: flex;
    justify-content: center;
}

.champaign-tab .nav-1 .child-nav {
    border-radius: 15px;
    border: 1px solid rgba(238, 238, 238, 0.80);
    background: #FFF;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
    width: 735px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    /* z-index: 999; */
}

.champaign-tab .nav-1 button {
    text-transform: capitalize;
    cursor: pointer;
    padding: 10px 20px;
    color: rgba(37, 37, 37, 0.80);
    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.champaign-tab .nav-1 button.active {
    position: relative;
    color: #ffffff;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
}
.video_section_image{
    width:100%;
    display: flex;
    justify-content: center;
}
.video_section_image .video_section-content {
    width: 100% !important;
    height: 300px !important;
    max-width: 600px;
}
.video_section_image .video_section-content iframe{
    border-radius: 20px;
}

.youtube_video{
    width:100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.youtube_video .youtube_video_content {
    width: 100% !important;
    height: 345px !important;
    max-width: 600px;
}
.youtube_video_content .video_section-content iframe{
    border-radius: 20px;
}

/* styles */
.details_donation .details-page-1 {
    display: none !important;
}

.details_donation .details-page-2 {
    display: block !important;
    /* z-index: 999; */
}
/* .slider_deatils_donation_bg{
    background-image: url('../../assets/images/details_donation/donation_bg.png');
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
}
.slider_deatils_donation_main .swiper-backface-hidden .swiper-slide{
    width: 100% !important;
} */
.donors_list .donors_list_title h2{
    color: #2c0202;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0px;
    padding-left: 10px;
    border-left: 4px solid #E25704;
}
.donors_list .donors_list_title h2 span{
    font-size: 14px;
    line-height: 22px;
}
.recommendedseva_4 .swiper-horizontal{
    padding-bottom: 0 !important;
}
.donation-main p{
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 20px;
}
.donation-main .swiper{
    z-index: -999;
}
.donation-main .swiper .swiper-wrapper .swiper-slide img{
    border-radius: 10px;
    /* max-width: 800px; */
    width: 100% !important;
    height: 100% !important;
}
.video_section-content{
    border-radius: 10px;
    max-width: 800px;
    width: 100% !important;
    height: 100% !important;
}

.donation-main_1{
    border-top: 1px solid rgba(0, 0, 0, 0.20);
    padding-top: 10px;
}
 
.donation-main .swiper.swiper-horizontal{
    padding: 0px 0px 10px 0px !important;
}
.donation-main .swiper .swiper-wrapper .swiper-slide{
  width: 100% !important;
  height: 350px !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}
.donation_main_updates .swiper.swiper-horizontal {
    padding: 0px 0px 52px 0px !important;
}
.donation_main_updates .swiper-pagination-horizontal {
    bottom: 19px !important;
}

@media (min-width:320px) and (max-width:344px) {
    .donation_bg_main {
        margin-top: 70px !important;
    }
}
@media (min-width:320px) and (max-width:767px) {
    /* .new_foundation_construct{
        position: absolute;
        top: 220px;
    } */
    .donation_bg {
        width: 100%;
        margin-top: 0px;
        display:block;
    }
    .donation_grid {
        max-width: 100% !important;
        -webkit-flex-basis: 100% !important;
    }
    .details_donation_sm .details-page-1{
        margin-top: -30px;
    }
    .product_content p {
        font-size: 16px;
        line-height: 26px;
    }
    
    .video_section_image .video_section-content {
        width: 100% !important;
        height: 100% !important;
    }
    .youtube_video .youtube_video_content {
        width: 100% !important;
        height: 100% !important;
    }

    /* styles */
    .champaign-tab .nav-1 .child-nav {
        display: flex;
        justify-content: center;
        width: 300px !important;
    }

    .champaign-tab .nav-1 button {
        padding: 5px 8px;
        font-size: 12px;
    }

    .champaign-tab .nav-1 button.active {
        font-size: 12px;
    }

    .details_donation .details-page-1 {
        display: block !important;
        margin-left: 15px;
    }

    .details_donation .details-page-2 {
        display: none !important;
    }
    .donation-main .swiper .swiper-wrapper .swiper-slide {
        width: 100% !important;
        height: 200px !important;
    }
    .video_section-content{
        border-radius: 10px;
        max-width: 800px;
        width: 100% !important;
        height: 100% !important;
    }
    .donation_bg_main {
        margin-top: 83px;
    }
}


@media (min-width:768px) and (max-width:900px) {
    .details_donation .details-page-1 {
        display: block !important;
        margin-left: 18px;
        margin-top: 80px;
    }
    .donation_bg {
        height: 311px;
        width: 100%;
        margin-top: 0px;
    }

    .details_donation .details-page-2 {
        display: none !important;
    }

    .details_donation {
        margin-bottom: 0 !important;
    }
    .donation-main .swiper .swiper-wrapper .swiper-slide {
        width: 100% !important;
        height: 300px !important;
    }
    .donation_grid {
        max-width: 100% !important;
        -webkit-flex-basis: 100% !important;
    }
}

@media(min-width:901px) and (max-width:1024px) {
    .details_donation .details-page-1 {
        display: none !important;
    }

    .details_donation .details-page-2 {
        display: block !important;
    }

}

@media(min-width:901px) and (max-width:1200px) {
    .champaign-tab .nav-1 button{
        padding: 10px 14px;
    }
    .donation-main .swiper .swiper-wrapper .swiper-slide {
        height: 300px !important;
    }
}

@media(min-width:901px) and (max-width:1300px) {
    .donation_bg{
        height: 420px;
    }
}
/* @media (min-width:1500px) {
    .donation_bg{
        height:641px;
    }
    .champaign-tab{
        margin-top: 90px;
    }
    .details_donation{
        margin-top: -155px;
    }
} */