@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  scroll-behavior: smooth;
}
#sevas-scroll {
  position: relative;
  top: -70px;
}
body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  
}

button:hover {
  background-color: unset !important;
}

.special_pujas_donations {
  margin: 50px 0px 0px 0px;
  padding-bottom: 40px;
}

.book-now a,
.welcome_book_now a {
  text-decoration: none;
}

.special_pujas_donations .donation_puja_buttons {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.donation_puja_buttons .btn {
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 0.8);
  background: #fff;
  width: 130px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c0202;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: unset;
  /* padding-left: 20px; */
  transition: 0ms;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 
}

.donation_puja_buttons .btn.active {
  border-radius: 8px;
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2.5px);
  color: #fff;
}

.donation_puja_buttons .btn:hover {
  border-radius: 8px;
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(2.5px);
  color: #fff;
}

.donation.btn {
  position: relative;
}

.donation.btn:hover .donation.btn::before {
  fill: #fff !important;
}

.donation_puja_buttons .puja {
  margin-left: 10px;
  position: relative;
}

.donation_puja_buttons .puja .og {
  position: absolute;
  right: 10px;
  bottom: -0px;
}

.donation_puja_buttons .puja .wh {
  position: absolute;
  right: 0px;
  bottom: -9px;
}

.donation_puja_buttons .btn.active .og-img {
  display: none !important;
}

.donation_puja_buttons .btn.active .wh-img {
  display: block !important;
}

.donation_puja_buttons .wh-img {
  display: none;
}

.donation_puja_buttons .og-img {
  display: block !important;
}

.donation_puja_buttons .donation_btn1 .og {
  position: absolute;
  right: 0px !important;
  bottom: -0px !important;
}

.donation_puja_buttons .donation_btn1 .wh {
  position: absolute;
  right: 0px !important;
  bottom: -0px !important;
}

.MuiTabs-scroller .MuiTab-textColorPrimary:not(:first-child) {
  margin-left: 10px;
}

.swiper-pagination-horizontal {
  bottom: 6px !important;
}

.swiper-horizontal {
  padding-bottom: 50px !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  border-radius: 08px !important;
}
.swiper-pagination-bullet-active {
  border-radius: 8px !important;
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  ) !important;
  width: 30px !important;
}

.special_pujas_donation_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 60px 0px;
  position: relative;
}
.special_pujas_donation_title::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/dharma-parirakshana/dharma-strip.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 380px;
  height: 60%;
  bottom: -65px;
}

.special_pujas_donation_title h3 {
  font-family: "Poppins", sans-serif;
  color: #2c0202;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin: 0px 0px 10px 0px;
  position: relative;
}
.special_pujas_donation_title h3::after {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/dharma-parirakshana/dharma-sm-right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 220px;
  height: 100%;
  right: -50%;
  top: -5px;
}
.special_pujas_donation_title h3::before {
  content: "";
  position: absolute;
  background-image: url("../../assets/images/dharma-parirakshana/dharma-sm-left.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 220px;
  height: 100%;
  left: -50%;
  top: -5px;
}

.special_pujas_donation_title p {
  color: #2c0202e3 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  width: 640px;
  text-align: center;
}

.seva_bookings {
  margin: 120px 0px 0px 0px;
  position: relative;
}

.seva_bookings .small_support_large_main .small_support_sm_left {
  position: absolute;
  left: 0;
  top: -104px;
}
.seva_bookings .small_support_large_main .small_support_sm_right {
  position: absolute;
  right: 0;
  top: -104px;
}
.seva_bookings_content {
  margin-top: 180px;
}

.seva_bookings_content h2 {
  color: #2c0202;
  font-family: "Quicksand", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin: 0px 0px 10px 0px;
  width: 380px;
}

.seva_bookings_content p {
  color: #2c0202e3 !important;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
}

.seva_booking_mini_main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.seva_booking_mini_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seva_booking_mini_content .seva_booking-image {
  width: 120px;
  position: relative;
}

.seva_booking_mini_content h3 {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 02px 0px;
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.seva_booking_mini_content {
  position: relative;
}

.seva_booking_mini_content p {
  color: #2c0202e3 !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
}

.seva_booking_mini_content {
  position: relative;
}
.our-special-offers {
  padding: 80px 0px;
}

.campaigner-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.campaigner-title h2 {
  font-family: "Poppins", sans-serif;
  color: #2c0202;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  margin: 0 0 0 0;
}

.campaigner-title p {
  color: #2c0202e3 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  margin: 0;
  text-align: center;
}

/* success popup */

.new-bg .MuiDialog-paperWidthSm {
  max-width: 1220px !important;
  background-color: unset;
  box-shadow: unset;
  width: 70%;
}

.new-bg .MuiDialog-scrollPaper {
  display: flex;
  align-items: flex-start;
}

/* .new-bg .Seva-Booking {
    padding: 30px 30px;
} */

.new-bg .Seva-Booking img {
  width: 85px;
  margin-bottom: 0;
}

.new-bg .Seva-Booking .Seva-Booking-text {
  margin-bottom: 5px;
}

.succes-boxing-bg {
  width: 900px;
  /* background-image: url('../../assets/images/sepical-offer/bg.png');
    background-repeat: no-repeat;
    background-size: cover; */
  height: 150px;
  /* background-position: center; */
  border-radius: 10px;
  margin-top: 40px;
  padding: 60px 30px 30px 30px;
}

.succes-boxing-bg h2 {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  margin: 0px 0px 10px 0px;
}

.succes-boxing-bg p {
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  margin: 0px;
  width: 460px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-height: 0px !important;
}

.our-special-offerss {
  margin-top: 30px;
}
.main-box .swiper-backface-hidden .swiper-slide {
  height: auto !important;
}
.seva_stipper img {
  width: 100%;
}
.seva_stipper {
  position: relative;
  bottom: -4px;
  left: 0;
}
.dharma_parirakshana_bg_left {
  position: absolute;
  left: 0;
  top: 100%;
}
.dharma_parirakshana_bg_left img {
  width: 300px;
}
.dharma_parirakshana_bg_right {
  position: absolute;
  right: 0;
  top: 100%;
}
.dharma_parirakshana_bg_right img {
  width: 300px;
}

.btn-whatsapp-pulse img {
  width: 60px;
  position: relative;
}
.btn-whatsapp-pulse {
  color: white;
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
.btn-whatsapp-pulse-border {
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 30px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 0.75;
  }
  75% {
    padding: 40px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .special_pujas_donations {
    margin: 25px 0px 0px 0px;
  }
  .new-bg .MuiDialogContent-root {
    padding: 0;
  }
  .special_pujas_donation_title {
    margin: 0;
  }
  .donation_puja_buttons .puja {
    margin-right: 0;
  }

  .special_pujas_donations .donation_puja_buttons button:not(:first-child) {
    margin-top: 10px;
  }

  .special_pujas_donation_title h3 {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  .special_pujas_donation_title h3::before {
    width: 45px;
    height: 100%;
    left: -26%;
    top: -2px;
  }
  .special_pujas_donation_title h3::after {
    width: 45px;
    height: 100%;
    right: -26%;
    top: -2px;
  }
  .special_pujas_donation_title {
    margin-bottom: 60px;
  }
  .special_pujas_donation_title::before {
    width: 300px;
    bottom: -75px;
  }
  .special-pujas-box .view-all {
    bottom: -30px !important;
  }

  .special_pujas_donation_title p {
    font-size: 13px;
    line-height: 23px;
    font-weight: 600;
    width: auto;
  }

  .special-pujas-box {
    margin-top: 20px;
  }
  .seva_bookings {
    margin: 40px 0px;
  }

  .seva_bookings_image img {
    width: 100%;
  }

  .seva_bookings_content {
    margin-top: 10px;
  }

  .seva_bookings_content h2 {
    font-size: 25px;
    line-height: 30px;
    width: auto;
    margin-bottom: 5px;
  }

  .seva_bookings_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .seva_booking_mini_main_content .seva_booking_mini_content {
    margin-left: 0;
  }
  .seva_booking_mini_content h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 2px;
  }
  .step_booking_main_box {
    margin-top: 20px;
  }
  .seva_booking_mini_content::before,
  .seva_booking_mini_content1::before,
  .seva_booking_mini_content2::before,
  .seva_booking_mini_content3::before {
    width: 0;
  }

  .seva_booking_mini_content p {
    width: auto;
  }

  .seva_booking_mini_main_content .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_1 {
    margin-top: 20px;
  }

  .seva_booking_mini_main_content_1 .seva_booking_mini_content {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_1 .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_2 .seva_booking_mini_content {
    margin-left: 0;
    margin-top: 20px;
  }

  .seva_booking_mini_main_content_2 {
    margin-right: 0;
  }

  .seva_booking_mini_main_content_2 .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_3 {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_3 .seva_booking_mini_content {
    margin-left: 0;
    margin-top: 20px;
  }

  .seva_booking_mini_main_content_3 .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_4 {
    margin-top: 20px;
  }
  .testimonial_title {
    margin-bottom: 20px !important;
  }
  .our-special-offers {
    padding: 30px 0px 0px 0px;
  }
  .special-offer-content {
    padding: 60px 40px 40px 40px;
  }
  .campaigner-title {
    margin-top: 30px;
  }
  .campaigner-title h2 {
    font-size: 25px;
    line-height: 35px;
  }

  .campaigner-title p {
    font-size: 15px;
    line-height: 25px;
    width: auto;
  }

  .seva_booking_mini_content .seva_booking-image {
    width: 60px;
  }

  .seva_booking_mini_main_content_1,
  .seva_booking_mini_main_content_2,
  .seva_booking_mini_main_content_3,
  .seva_booking_mini_main_content_4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* success popup */
  .new-bg .MuiDialog-paperWidthSm {
    max-width: 100% !important;
    background-color: unset;
    box-shadow: unset;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .succes-boxing-bg {
    width: auto;
    height: 100%;
  }

  .succes-boxing-bg h2 {
    font-size: 20px;
    line-height: 24px;
  }
  .succes-boxing-bg p {
    width: auto;
  }
  .Seva-Booking h6 {
    width: auto !important;
  }
  .main_campaigner_card {
    margin-bottom: 50px;
  }
  .pujas_box h3 {
    font-size: 18px;
    line-height: 28px;
  }
  .main-box .swiper-backface-hidden .swiper-slide {
    height: 100% !important;
  }
  .dharma_parirakshana_bg_left img {
    width: 0px;
  }
  .dharma_parirakshana_bg_right img {
    width: 0px;
  }
  .btn-whatsapp-pulse img {
    width: 50px;
    position: relative;
  }
  .btn-whatsapp-pulse {
    bottom: 10px;
    right: 30px;
  }
  #sevas-scroll {
    position: relative;
    top: 330px;
  }
}
@media (min-width: 320px) and (max-width: 372px) {
  .swiper-horizontal {
    padding-bottom: 80px !important;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .new-bg .Seva-Booking {
    width: 100% !important;
  }

  .Seva-Booking h6 {
    margin-bottom: 0;
  }

  .succes-boxing-bg {
    width: 100% !important;
    height: 100%;
  }
  .special_pujas_donations .donation_puja_buttons {
    display: flex;
    /* flex-direction: column; */
    margin-bottom: 25px;
  }
  .special_pujas_donations .donation_puja_buttons button:not(:first-child) {
    margin: 0px 0px 0px 10px;
  }
  .view-all {
    margin-bottom: 0 !important;
    margin-top: 0;
  }
  .seva_stipper {
    bottom: -0px;
  }
}

@media (min-width: 401px) and (max-width: 500px) {
  .new-bg .Seva-Booking {
    width: 100% !important;
  }

  .Seva-Booking h6 {
    margin-bottom: 0;
  }
  .special_pujas_donations .donation_puja_buttons {
    display: flex;
    margin-bottom: 20px;
  }
  .special_pujas_donations .donation_puja_buttons button:not(:first-child) {
    margin: 0px 0px 0px 10px;
  }
  .view-all {
    margin-bottom: 0 !important;
  }
  .seva_stipper {
    bottom: -0px;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  .new-bg .Seva-Booking {
    width: 360px !important;
  }

  .Seva-Booking h6 {
    margin-bottom: 0;
  }
  .view-all {
    margin-bottom: 0 !important;
  }
  .seva_stipper {
    bottom: -0px;
  }
}
@media (min-width: 600px) and (max-width: 767px) {
  .view-all {
    margin-bottom: 0 !important;
  }
  .seva_stipper {
    bottom: -0px;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .seva_bookings_content {
    margin-top: 10px;
  }

  .seva_booking_mini_main_content_4 {
    margin-top: 20px;
  }
  .special_pujas_donation_title h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .seva_bookings .small_support_large_main .small_support_sm_right {
    top: -23px;
  }
  .seva_bookings .small_support_large_main .small_support_sm_left {
    top: -23px;
  }
  .special_pujas_donation_title h3::before {
    width: 70px;
    height: 100%;
    left: -26%;
    top: -2px;
  }
  .special_pujas_donation_title h3::after {
    width: 70px;
    height: 100%;
    right: -26%;
    top: -2px;
  }
  .special_pujas_donation_title {
    margin-bottom: 90px;
  }
  .special_pujas_donation_title::before {
    width: 300px;
    bottom: -75px;
  }
  .special-pujas-box .view-all {
    bottom: 4px !important;
  }
  .btn-whatsapp-pulse {
    bottom: 100px;
    right: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .seva_bookings {
    margin: 40px 0px;
  }

  .seva_bookings_content h2 {
    font-size: 30px;
    line-height: 40px;
    width: auto;
  }

  .seva_bookings_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .seva_booking_mini_main_content {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .step_booking_main_box {
    margin-top: 20px;
  }

  .seva_booking_mini_main_content_1 {
    margin-top: 40px;
  }

  .seva_booking_mini_main_content_1 .seva_booking_mini_content {
    margin-left: 0;
  }

  .seva_booking_mini_content::before,
  .seva_booking_mini_content1::before,
  .seva_booking_mini_content2::before,
  .seva_booking_mini_content3::before {
    width: 0;
  }

  .seva_booking_mini_main_content_2 .seva_booking_mini_content {
    margin-left: 0;
    margin-top: 40px;
  }

  .seva_booking_mini_main_content_2 {
    margin-right: 0;
  }

  .seva_booking_mini_main_content_3 {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_3 .seva_booking_mini_content {
    margin-left: 0;
    margin-top: 40px;
  }

  .seva_booking_mini_main_content .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_3 .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_2 .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  .seva_booking_mini_main_content_1 .seva_booking_mini_content .curve_bg img {
    width: 0;
  }

  /* success popup */

  .new-bg .MuiDialog-paperWidthSm {
    width: auto;
    max-width: 700px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .succes-boxing-bg {
    width: 600px;
    padding: 30px;
  }
  .campaigner-title h2 {
    font-size: 25px;
    line-height: 35px;
  }
  .view-all {
    margin-top: 0px;
    margin-bottom: 0 !important;
  }
  .seva_stipper {
    bottom: -0px;
  }
  #sevas-scroll {
    position: relative;
    top: 330px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .seva_booking_mini_main_content_1 .seva_booking_mini_content {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_1 .seva_booking_mini_content .curve_bg {
    right: -46%;
    bottom: -40%;
  }

  .seva_booking_mini_main_content_2 .seva_booking_mini_content {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_2 {
    margin-right: 30px;
  }

  .seva_booking_mini_main_content_3 {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_3 .seva_booking_mini_content {
    margin-left: 0;
  }

  .seva_booking_mini_main_content_1 .seva_booking_mini_content::before {
    left: 10%;
  }

  .seva_booking_mini_main_content_2 .seva_booking_mini_content::before {
    left: 80%;
  }

  /* success popup */

  .new-bg .MuiDialog-paperWidthSm {
    width: 100%;
  }

  .succes-boxing-bg {
    width: 650px;
    padding: 30px;
  }
  .view-all {
    margin-bottom: 0 !important;
  }
  .seva_stipper {
    bottom: -0px;
  }
}

/* success popup */

@media (min-width: 1200px) and (max-width: 1399px) {
  .new-bg .MuiDialog-paperWidthSm {
    width: 100%;
  }

  .succes-boxing-bg {
    width: 900px;
    padding: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1499px) {
  .view-all {
    margin-bottom: 0 !important;
  }
  .seva_stipper {
    bottom: -0px;
  }
}
