.donors_list{
    margin-top: 50px;
}
.donors_list_title .MuiBox-root{
    margin-top: 20px;
    border-bottom: unset !important;
}
.donors_list_title .MuiBox-root .MuiTabs-flexContainer{
    width: 653px;
    border-radius: 35px;
    border: 1px solid rgba(238, 238, 238, 0.80);
    background: #FFF;
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.donors_list_title .MuiBox-root .MuiTabs-flexContainer .MuiButtonBase-root{
    width: 320px !important;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 22px;
    text-transform: unset;
}
.donors_list_title .MuiBox-root .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected{
    color: #ffffff;
    font-family: "Quicksand", sans-serif;
    border-radius: 35px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
    border-bottom: unset !important;
}
.donors_list_title .MuiBox-root .MuiTabs-indicator{
    background-color: unset !important;
}

.recent_mostgeneous, .modal_donors_list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.recent_mostgeneous_list, .modal_donors .recent_mostgeneous_list{
    width: 50%;
}
.recent_mostgeneous_list_items img{
    width: 60px;
}
.recent_mostgeneous_list_content h3{
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    line-height: 23px;
    font-weight: 900;
    margin: 0;
    width: 123px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.recent_mostgeneous_list_content h5{
    font-family: "Quicksand", sans-serif;
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
}
.recent_mostgeneous_list_content p{
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    font-style: italic;
    color: #2c0202e3 !important;
    margin: 0;
}
.recent_mostgeneous_list{
    display: flex;
}
.recent_mostgeneous_list_content{
    margin-left: 20px;
}
.recent_mostgeneous_list{
    margin-top: 15px;
}
.main_recent_generous{
    display: flex;
    justify-content: space-between;
    width: 500px;
}
.modal_donors{
    display: flex;
    justify-content: space-between;
}
.modal_donors .recent_mostgeneous_list{
    margin-bottom: 20px;
}
.donar_main_modal .MuiPaper-root{
    width: 900px;
}
.donar_main_modal .MuiPaper-root .MuiDialogContent-dividers{
    padding: 20px 40px;
}
.donar_main_modal .MuiDialogTitle-root{
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}
.donar_main_modal .MuiDialogTitle-root span{
    font-size: 15px;
    line-height: 22px;
}
.all_donars_button{
    width: 100%;
    display: flex;
    justify-content: center;
}
.all_donars_button .MuiButtonBase-root{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #E25704;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 25px;
    padding: 08px 20px;
    border-radius: 06px;
    text-transform: unset;
    border: 1px solid #E25704;
}
.all_donars_button .MuiButtonBase-root:hover{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding: 08px 20px;
    border-radius: 06px;
    text-transform: unset;
    border: 1px solid #E25704;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
}
.donors_more.MuiButtonBase-root{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #E25704;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    padding: 08px 20px;
    border-radius: 06px;
    text-transform:initial !important;
    border: 1px solid #E25704 !important;
}
.donors_more.MuiButtonBase-root:hover{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding: 08px 20px;
    border-radius: 06px;
    text-transform:initial !important;
    border: 1px solid #E25704;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
}
.more_donors{
    display: flex;
    justify-content: center;
    width: 450px;
    margin-top: 20px;
}
@media (min-width:320px) and (max-width:420px) {
    .donors_list{
        margin-top: 20px;
    }
    .donors_list .donors_list_title h2{
        font-size: 18px;
        line-height: 26px;
    }
    .donors_list_title .MuiBox-root.css-19kzrtu{
        margin: 0px 0px 0px 0px;
    }
    .donors_list_title .MuiBox-root .MuiTabs-flexContainer .MuiButtonBase-root{
        width: 50% !important;
    }
    .main_recent_generous{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: auto;
    }
    .donors_list_title .MuiBox-root .MuiTabs-flexContainer{
        width: 290px !important;
    }
    .more_donors{
        width: auto;
    }
    .donar_main_modal .modal_donors{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .donar_main_modal .MuiPaper-root .MuiDialogContent-dividers{
        padding: 20px;
    }
    .recent_mostgeneous, .modal_donors_list{
        flex-wrap: nowrap;
        display: flex;
        flex-direction: column;
    }
    .recent_mostgeneous_list{
        width: 100% !important;
    }
    .modal_donors .recent_mostgeneous_list{
        margin-bottom: 10px;
    }
}
@media (min-width:421px) and (max-width:767px) {
    .donors_list{
        margin-top: 20px;
    }
    .donors_list .donors_list_title h2{
        font-size: 18px;
        line-height: 26px;
    }
    .donors_list_title .MuiBox-root.css-19kzrtu{
        margin: 0px 0px 0px 0px;
    }
    .donors_list_title .MuiBox-root .MuiTabs-flexContainer .MuiButtonBase-root{
        width: 50% !important;
    }
    .main_recent_generous{
        display: flex;
        width: auto;
    }
    .recent_mostgeneous_list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .recent_mostgeneous_list_content{
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .donors_list_title .MuiBox-root .MuiTabs-flexContainer{
        width: 290px !important;
    }
    .more_donors{
        width: auto;
    }
    .modal_donors .recent_mostgeneous_list{
        margin-bottom: 10px;
    }
    .recent_mostgeneous_list_content h3{
        width: 123px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }
}
@media (min-width:320px) and (max-width:900px) {
    .recent_mostgeneous_list_content h3{
        width: 123px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .modal_donors .recent_mostgeneous_list{
        margin-bottom: 10px;
    }
}