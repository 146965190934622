.main_payment_cancel{
    margin-top: 90px;
    margin-bottom: 40px;
}
.payment_cancel{
    width: 100%;
    display: flex;
    justify-content: center;
}
.main_payment_cancel .our-special-offerss{
    margin-bottom: 50px;
}
.main_payment_cancel .Seva-Booking .Seva-Booking-text h5{
    font-size: 25px;
    line-height: 34px;
}
.main_payment_cancel .Seva-Booking-text p{
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    margin: 0px 0px 10px 0px;
}
.main_payment_cancel .Seva-Booking-text p span{
    background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
 
}
.main_payment_cancel .Seva-Booking img{
    width: 120px;
    margin-bottom: 10px;
}
.main_payment_cancel .Seva-Booking .Seva-Booking-text{
    margin-bottom: 0px !important;
}
.main_new_payment{
    width: 100%;
    height: 700px;
}
.thankyou_card{
    width: 42%;
}
.new-payment{
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/thankyou-sm/bg.png');
    background-repeat: no-repeat;
    background-size:contain;
    position: relative;
}
.new-payment-content{
    position: relative;
    left: 16%;
    top: 8%;
    width: 300px;
}
.new-payment-content h3{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    color: #c98a1b;
 
}
.new-payment-content p{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}
.new-payment-content img{
    width: 290px;
    margin-top: 05px;
}
.new-payment-content_2{
    position: relative;
    left: 16%;
    top: 10%;
    width: 300px;
}
.new-payment-content_2 h2{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 25px;
    line-height: 38px;
    font-weight: 700;
    margin: 0;
    text-align: center;
    background-color: hsl(48, 99%, 49%);
    padding: 4px;
    text-transform: uppercase;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
}
.font-28 {
  font-size: 28px;
}
.font-20 {
  font-size: 20px;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-12 {
  font-size: 12px;
}
.new-payment-content_2 h4{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.new-payment-content_3{
    position: relative;
    left:17%;
    top: 12%;
    width: 300px;
}
.new-payment-content_3 h3{
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    text-align: center;
    letter-spacing: 1px;
}
.new-payment-content_3 h4{
    font-family: "Poppins", sans-serif;
    /* font-family: "Montserrat", sans-serif; */
    font-style: normal;
    font-size: 12px;
    line-height: 22px;
    font-weight: 700;
    margin: 0px 0px 0px 0px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: capitalize;
 
}
.new-payment-content_4{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 15%;
    bottom: -19%;
    width: 300px;
}
.new-payment-content_4 img{
    width: 100px;
}
.new-payment-content_4 h4{
    font-family: "Poppins", sans-serif;
    /* font-family: "Montserrat", sans-serif; */
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    text-align: center;
    letter-spacing: 1px;
}
.payment_1{
    position: relative;
}
.payment_1 .share_achievement{
    position: absolute;
    top: 23px;
    left: 8%;
    z-index: 999;
}
.new-payment .share_achievement{
    position: absolute;
    top:10px;
    right: 10px;
}
.share_achievement ul{
    padding: 0;
    margin: 0;
    display:flex;
}
.share_achievement ul li{
    background-color: #FFF;
    padding: 5px;
    border-radius: 4px;
    list-style: none;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
/* .share_achievement ul li{
    background-color: #FFF;
    padding: 5px;
    border-radius: 4px;
    list-style: none;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
} */
.share_achievement ul li button{
    background-color: #FFF;
    border-radius: 4px;
    list-style: none;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    border: 0;
    cursor: pointer;
}
.share_achievement ul li:not(:first-child){
    margin-left: 10px;
}
.share_achievement ul li img{
    width: 15px;
}
.get-your-tx-certificate{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 10px;
    height: 272px;
    border-radius: 4px;
    margin-left: 20px;
    width: 320px;
}
.get-your-tx-certificate h4{
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
}
.get-your-tx-certificate p{
    font-size: 10px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
}
.get-your-tx-certificate .get-your-tx-certificate_pan{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.get-your-tx-certificate .get-your-tx-certificate_pan label{
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
}
.get-your-tx-certificate .get-your-tx-certificate_pan input{
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
}
.continue_certificate{
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}
.get-your-tx-certificate_pan .continue{
    background: linear-gradient(90deg, rgba(200,66,4,1) 28%, rgba(244,144,4,1) 105.28%) !important;
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    width: 100%;
    padding: 5px 0px;
    text-transform: unset;
    border-radius: 6px;
    margin-top: 10px;
}
.get-your-tx-certificate_pan .certificate{
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    border: 1px solid #f49004;
    background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    width: 100%;
    padding: 5px 0px;
    text-transform: unset;
    border-radius: 6px;
    margin-top: 10px;
}
.eight_g_expanded{
    width: 30%;
    margin-left: 10px;
}
.get-your-tx-certificate_pan_label{
    margin-bottom: 5px;
}
.eight_g_submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.eight_g_submit Button{
    background: linear-gradient(90deg, rgba(200, 66, 4, 1) 28%, rgba(244, 144, 4, 1) 105.28%) !important;
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    width: 100%;
    max-width: 70%;
    padding: 5px 0px;
    text-transform: unset;
    border-radius: 6px;
}
.eighty_g_modal .MuiDialog-paper{
    max-width: 800px;
}
.submitting_details{
    margin-top: 40px;
}
.submitting_details_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.submitting_details_content p{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
    margin: 0px 0px 10px 0px;
}
.submitting_details_content img{
   width: 80px;
}
.tax_receipt_title{
    margin-top: 50px;
 }
.tax_receipt_title h4{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
 }
.tax_receipt_mail{
    background: #DDD;
    padding: 20px;
    border-radius: 6px;
}
.tax_receipt_mail p{
   font-size: 14px;
   line-height: 26px;
   font-weight: 400;
   margin: 0px 0px 5px 0px;
   text-align: justify;
   font-weight: 600;
}
.eight_g_expanded{
    margin-bottom: 40px;
}
.sm-certificate{
    display: none;
}

.eighty_g_modal .MuiModal-backdrop{
    background-color: rgba(0, 0, 0, -0.88) !important;
    backdrop-filter: blur(1px) !important;
}

@media (min-width:320px) and (max-width:767px) {

.thankyou_card{
    width: auto;
}
.new-payment-content h3 {
    font-size: 20px;
    line-height: 28px;
}
.new-payment-content img{
    width: 150px;
}
.new-payment .new-payment-content{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.new-payment-content_2 h2{
    font-size: 15px;
    line-height: 25px;
    width: 193px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.new-payment-content_2{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 12%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.new-payment-content_3{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.new-payment-content_3 h3{
    font-size: 11px;
    margin-bottom: 0;
}
.share_achievement ul li:nth-child(1){
    display: none;
}
.share_achievement ul li:not(:first-child) {
    margin-left: 0px;
}
.payment_1 .share_achievement{
    left: 9%;
}
.new-payment-content_4{
    transform: translate(-50%, -50%);
    left: 50%;
    top: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main_new_payment{
    height: 500px;
}
.new-payment-content_2 h4{
    font-size: 12px;
}
}
@media (min-width:350px) and (max-width:374px) {
    .thankyou_card{
        width: 89% !important;
    }
}
@media (min-width:320px) and (max-width:374px) {
    .main_new_payment{
        height: 400px;
    }
    .thankyou_card {
        width: 94%;
    }
    .new-payment-content_3{
        width: 200px;
    }
    .new-payment-content_4 img{
        width: 60px;
    }
    .new-payment-content_4{
        top: 11%;
    }
}
@media (min-width:420px) and (max-width:600px) {
    .main_new_payment{
        height: 600px;
    }
    .new-payment-content_2{
        transform: translate(-50%, -50%);
        left: 50%;
        top: 16%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .new-payment-content_3{
        transform: translate(-50%, -50%);
        left: 50%;
        top: 18%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .new-payment-content_4{
        transform: translate(-50%, -50%);
        left: 49%;
        top: 21%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (min-width:500px) and (max-width:600px) {
    .thankyou_card {
        width: 80%;
    }
    .main_new_payment{
        height: 600px;
    }
}
@media (min-width:601px) and (max-width:767px) {
    .thankyou_card {
        width: 68%;
    }
    .main_new_payment{
        height: 600px;
    }
    .new-payment-content_2{
        transform: translate(-50%, -50%);
        left: 50%;
        top: 16%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .new-payment-content_3{
        transform: translate(-50%, -50%);
        left: 50%;
        top: 18%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .new-payment-content_4{
        transform: translate(-50%, -50%);
        left: 49%;
        top: 21%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width:768px) and (max-width:991px) {
.eight_g_expanded{
    width: 100%;
}
.thankyou_card {
    width: 67%;
}
.new-payment-content h3{
    font-size: 22px;
    line-height: 28px;
}
.new-payment-content p{
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    margin-top: 05px;
}
.new-payment-content img {
    width: 180px;
    margin-top: 10px;
}
.new-payment-content {
    width: 197px;
    top: 12%;
    left: 26%;
}
.new-payment-content_2 {
    width: 190px;
    left: 26%;
    top: 16%;
}
.new-payment-content_2 h2{
    font-size: 20px;
    line-height: 28px;
    width: 193px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.new-payment-content_3 {
    left: 16%;
    top: 20%;
}
.new-payment-content_3 h3{
    font-size: 12px;
    line-height: 22px;
}
.new-payment-content_4 {
    left: 15%;
    bottom: -23%;
}
/* 
.sm-certificate{
    margin: 0px 0px 40px 0px;
    background-image: url('../../assets/images/mobile-thankyou.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 500px;
    position: relative;
   
}
.sm-certificate .share_achievement{
    position: absolute;
    right: 10px;
    top: 20px;
}
.sm-certificate_step{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25%;
    width: 200px;
    left: 50px;
}
.sm-certificate_step h3{
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    line-height: 32px;
    font-weight: 800;
    margin: 0px 0px 0px 0px;
}
.sm-certificate_step p{
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 25px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    text-align: center;
}
.sm-certificate_step img{
    width: 120px;
    margin-top: 10px;
}
.sm-certificate_presented{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 45%;
    width: 200px;
    left: 50px;
}
.sm-certificate_presented h4{
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    margin: 10px 0px 5px 0px;
}
.sm-certificate_presented h2{
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    background-color: hsl(48, 99%, 49%);
    font-weight: 800;
    padding: 5px;
    text-transform: uppercase;
    width: 190px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sm-certificate_campaign{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 65%;
    width: 200px;
    left: 50px;
}
.sm-certificate_campaign h3{
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 24px;
    margin: 10px 0px 0px 0px;
    font-weight: 600;
    text-align: center;
}
.sm-certificate_campaign h4{
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    text-align: center;
}
.thankyou_card{
    width: 100%;
} */
}
 
@media (min-width:901px) and (max-width:991px) {
    .thankyou_card{
        width: 66%;
    }
    .eight_g_expanded{
        margin-right: 10px;
    }
}
 
 
@media (min-width:992px) and (max-width:1299px) {
.get-your-tx-certificate {
    width: 264px;
}
.new-payment-content {
    position: relative;
    left: 12%;
    top: 10%;
    width: 300px;
}
.new-payment-content h3 {
    font-size: 28px;
    line-height: 38px;
}
.new-payment-content img{
    width: 280px;
}
.new-payment-content_2 {
    position: relative;
    left: 22%;
    top: 11%;
    width: 200px;
}
 
.new-payment-content_2 h2 {
    font-size: 18px;
    line-height: 26px;
    width: 193px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.new-payment-content_3 h3 {
    font-size: 13px;
    line-height: 23px;
    margin: 0;
}
.new-payment-content_3 {
    position: relative;
    left: 12%;
    top: 16%;
}
.app_new_main_payment{
    display: flex;
}
.thankyou_card{
    width: 42%;
}
.main_new_payment {
    height: 565px;
}
.new-payment{
    background-size: contain;
}
.new-payment-content p{
    font-size: 12px;
    line-height: 20px;
    text-align: center;
}
.new-payment-content_4{
    bottom: -18%;
    left: 10%;

}
.payment_1 .share_achievement{
    left: 8% !important;
}
}
@media (min-width:1200px) and (max-width:1299px) {
    .thankyou_card{
        width: 35%;
    }
}