.profile {
  margin-top: 120px;
  margin-bottom: 60px;
}

.profile_menu_bg {
  border-radius: 0px 20px 20px 0px;
  background: #fcfff8;
  box-shadow: 5px 5px 40px 0px rgba(0, 0, 0, 0.05);
}

.profile_menu_details {
  background-image: url("../../assets/images/profile/bg.png");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px;
}
.profile-adress {
  margin-top: 10px;
}

.profile_menu_details_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile_menu_details_list img {
  margin-bottom: 10px;
}

.profile_menu_details_list h3 {
  color: #2a2c36;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 10px 0px;
}

.profile_menu_details_list p {
  color: #939aac;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin: 0px;
}

.profile_menu_list {
  padding: 30px 0px;
}

.profile_menu_list .MuiAccordionSummary-content {
  color: #2c2c2c;
  color: #2c0202;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: 0 !important;
  text-decoration: unset !important;
}

.profile_menu_list .profile_list ul li a {
  text-decoration: none !important;
}

.profile_menu_list .MuiPaper-elevation1 {
  box-shadow: unset;
  margin-bottom: 0 !important;
}

.profile_menu_list .MuiPaper-elevation1::before {
  border: unset;
  background-color: unset;
}

.profile_menu_list .accordion1.Mui-expanded {
  margin: 0px 0px !important;
  min-height: 48px !important;
}

.accordion1 .Mui-expanded {
  min-height: 48px !important;
}

.profile_menu_list .MuiAccordionSummary-content img {
  margin-right: 10px;
}
.MuiPaper-elevation .profile_list ul {
  padding: 0;
  margin: 0px 0px 0px 20px;
}
.profile_list ul li {
  list-style: none;
}

.profile_list ul li:not(:last-child) {
  margin-bottom: 20px;
}

.profile_list ul li a {
  color: #808080;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  text-decoration: none;
}

.profile_list ul li img {
  margin-right: 16px;
  filter: grayscale();
}

.profile_list .active a {
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .profile_list .active {
    border-bottom: 2px solid #a92902;
    font-weight: 900;
    padding-bottom: 10px;
}

.profile_list .active a img {
  filter: unset;
}

/* .profile_list ul li:hover a {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} 

.profile_list ul li:hover {
    padding-bottom: 10px;
    border-bottom: 2px solid #A92902;
}

 .profile_list ul li:hover img {
    filter: unset;
}

.profile_list ul li.active img {
    filter: unset;
}

.profile_list ul li.active {
    padding-bottom: 10px;
    border-bottom: 2px solid #A92902;
}

.profile_list ul li.active a {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */

.logout {
  margin-top: 80px;
  margin-left: 10px;
}

.logout button {
  /* color: #f45050; */
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: unset;
  background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFF;
}

.logout button img {
  margin-right: 10px;
}

.accordion1 a {
  text-decoration: none;
}
.gdownload h2 {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  margin: 0 !important;
}
.invoice-deatils {
  margin-top: 30px;
  width: 150px;
}
.invoice-deatils a {
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #2c0202;
  text-decoration: none;
}
.invoice-deatils a img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.accordion1.active .MuiAccordionSummary-content {
  background: linear-gradient(
    276deg,
    #a92902 -4.36%,
    #e25704 49.89%,
    #f49004 105.28%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* .accordion1.active img{
    filter: unset !important;
} */
.profile_menu_list .MuiAccordionSummary-content img {
  filter: grayscale();
}
.accordion1.active .user_bg_main {
  filter: unset !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .profile {
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .profile_menu_details_list img {
    width: 80px !important;
    height: 80px !important;
  }
  /* .css-12zco34-MuiStack-root{
    display: flex !important;
    flex-direction: column !important;
} */
}
.image-container_main {
  display: flex !important;
  flex-direction: column !important;
  margin: 0 !important;
}
@media (min-width: 320px) and (max-width: 425px) {
  .userprofile_right_bg {
    margin-top: 10px;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .profile {
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .profile_menu_details_list img {
    width: 120px !important;
    height: 120px !important;
  }
}
