.pujas_box {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FFF;
    padding: 30px 15px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    -moz-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    height: 100%;
    position: relative;
}
.donation_cards_container{
    max-width: 1309px !important;
}
.pujas_box span{
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 7px;
    cursor: pointer;
}

.pujas_box:hover {
    box-shadow: 0px 5px 52px 1px rgba(0, 0, 0, 0.2);
}

.pujas_box:hover .MuiButtonBase-root.btn {
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    mix-blend-mode: unset;
    opacity: unset;
}
.pujas_box .puja_featured_image{
    width: 100%;
    height: 210px;
    overflow: hidden;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 10px;
}
.pujas_box img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.pujas_box h3 {
    color: #2c0202;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0px 0px 5px 0px;
}

.pujas_box p {
    color: #2c0202e3 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}

.pujas_box h4 {
    color: #2c0202;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    margin: 10px 0 0 0;
    border-top: 1px solid #DDD;
    padding-top: 10px;
}
.pujas_box h4 span{
    color: #2c0202e3 !important;
    font-family: "Poppins", sans-serif;
}

.pujas_box h5 {
    color: #2c0202e3 !important;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin: 0;
    padding-top: 10px;
}

.book-now .btn {
    background: linear-gradient(90deg, rgba(200,66,4,1) 28%, rgba(244,144,4,1) 105.28%) !important;
    color: #FFF;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    width: 100%;
    padding: 5px 0px;
    text-transform: unset;
    display: flex;
    align-items: center;
    border-radius: 6px;
    margin-top: 20px;
}
.book-now .btn:hover{
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%) !important;
    mix-blend-mode: unset !important;
    opacity: unset !important;
}

.book-now .btn img {
    width: 30px;
    margin-left: 10px;
    margin-bottom: 0;
}
.special-pujas-box{
    position: relative;
}
.special-pujas-box .view-all{
    position: absolute;
    right: 0;
    bottom: 0;
}
.view-all {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    z-index: 999;
}
.view-all a{
    text-decoration: none;
}
.view-all a .MuiButton-root{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
    background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: unset;
    padding: 0;
   
}

@media (min-width:320px) and (max-width:767px) {
    .pujas_box {
        box-shadow: 0px 5px 52px 1px rgba(0, 0, 0, 0.2);
    }

    .pujas_box .MuiButtonBase-root.btn {
        background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
        mix-blend-mode: unset;
        opacity: unset;
    }
    .view-all{
        margin: 20px 0 0 0;
    }
    .pujas_box .puja_featured_image{
        height: 190px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .pujas_box {
        box-shadow: 0px 5px 52px 1px rgba(0, 0, 0, 0.2);
    }

    .pujas_box .MuiButtonBase-root.btn {
        background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
        mix-blend-mode: unset;
        opacity: unset;
    }

}
.tax-benfits-strip{
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 1px 8px;
    border-radius: 25px 0 0 25px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    position: absolute;
    top:04px;
    right:3px;
    border: 1px solid #fff;
    border-right: 0;
}