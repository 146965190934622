.user_right {
    margin-left: 20px;
}

.gdownload ul {
    list-style: none;
}

.doc-80G {
    display: flex;
    align-items: center;
    gap: 5px;
}

.gdownload {
    width: 100%;
}

.gdown svg {
    background: rgba(145, 138, 138, 0.368);
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
}

.document-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.document-item {
    width: calc(33.33% - 10px);
    margin-bottom: 20px;
    display: flex;

}

.document-item img {
    width: 100%;
    height: auto;
}

.doc-80G svg {
    font-size: 20px;
    cursor: pointer;
}

.userprofile_right_bg,
.save_info_details {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 20px;
}

.date_picker_new .MuiStack-root {
    padding-top: 0 !important;
    overflow: hidden !important;
}

.date_picker_new .MuiStack-root input {
    color: #2c0202;
    font-family: "Quicksand", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.date_picker_new .MuiStack-root .MuiOutlinedInput-notchedOutline {
    border-color: rgb(26 25 25 / 14%) !important;
}

.date_picker_new .MuiStack-root .MuiIconButton-edgeEnd svg {
    fill: #e15604;
}

.userprofile_right_bg_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.userprofile_right_bg_content .personal_info h3 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    color: #2c0202;
}

.userprofile_right_bg_content .edit_button button {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: unset;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    border-radius: 50px;
    padding: 08px 20px 08px 20px;
    text-transform: unset;
    border: 1px solid #e15604;
    display: flex;
    align-items: center;
}

.userprofile_right_bg_content .edit_button button img {
    margin-left: 8px;
}

.edit_button {
    display: flex;
    gap: 10px;
}

.readonly-text-field input[disabled] {
    background-color: #f2f2f2;
    border: none !important;
    color: #686868;
    border-radius: 06px;
}

.profile_menu_details_list img {
    border: 0.5px solid rgb(104, 104, 104);
    border-radius: 50%;
    object-fit: cover;
    width: 120px;
    height: 120px;
}

/* .family-scroll{
    height: 800px;
    overflow-y: scroll;
} */

.userprofile_right_bg .personal-info {
    border-radius: unset;
    border: unset;
    padding: 0;
}

.product_content {
    margin-top: 20px;
}

.product_content p {
    color: #2c0202e3 !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}
.campaigner-modal .rest_save_button .rest{
    border-radius: 6px;
}
.campaigner-modal .rest_save_button .save{
    border-radius: 6px;
}
.campaigner-modal .rest_save_button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
}
.rest_save_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.rest_save_button .rest {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: unset;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    border: 1px solid #e15604;
    border-radius: 50px;
    padding: 8px 20px 6px 20px !important;
    min-width: 0px;
    text-transform: unset;
    margin-right: 10px;
}

.rest_save_button .save {
    font-family: "Quicksand", sans-serif;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding: 5px 30px !important;
    border-radius: 80px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    text-transform: unset;
    margin-left: 10px;
    min-width: 0px;
}

.save_info_details {
    margin-top: 20px;
}

.save_info_details .person_save_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person_save_info h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    color: hsla(0, 0%, 31%, 1);
}
.person_save_info .save_profile_image {
    margin-bottom: 10px;
    width: 80px;
}
.person_save_info .edit_button button {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: unset;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    border: 1px solid #e15604;
    border-radius: 05px;
    padding: 08px 10px 06px 10px;
    display: flex;
    align-items: center;
}

.person_save_info .edit_button button img {
    margin-left: 05px;
}

.relation_rashi_details_main {
    margin-top: 50px;
}

.relation_rashi_details {
    width: 100%;
    display: flex;
    align-items: center;

}
.edit-delete {
    display: flex;
    gap: 5px;
}
.edit-delete .edit_button button img{
    width: 14px;
    height: 14px;
}

.relation_rashi_details_list {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: flex-start;
    width: 33.3%;
    margin-bottom: 5px;
}

.relation_rashi_details_list h4 {
    font-size: 12px;
    line-height: 22px;
    font-weight: 800;
    margin: 0px 0px 0px 0px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
}

.relation_rashi_details_list p {
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.relation_rashi_details_list p span {
    color: hsla(210, 12%, 16%, 0.75);
}

.relation_rashi_details_2 {
    margin-top: 10px;
}

.relation_rashi_details_main .notes {
    margin-top: 10px;
}

.relation_rashi_details_main .notes h4 {
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
}

.relation_rashi_details_main .notes p {
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    width: 460px;
}

.relation_rashi_details_main .add_member p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
    color: hsla(0, 0%, 31%, 0.65);
}

.relation_rashi_details_main .add_more button,
.add_more button {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: unset;
    background: linear-gradient(276deg, #A92902 -4.36%, #E25704 49.89%, #F49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFF;
    border: 1px solid #e15604;
    border-radius: 50px;
    padding: 10px 20px 10px 20px;
    margin-bottom: 20px;
}

.relation_rashi_details_main .add_more button img,
.add_more button img {
    margin: 0px 0px 2px 5px;
}

.add_member {
    margin-top: 30px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.add_member p{
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}
.add_more {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:320px) and (max-width:520px) {
    .relation_rashi_details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    .rest_save_button {
        display: flex;
        flex-direction: column-reverse;
        /* justify-content: center; */
    }

    .rest_save_button .save {
        margin: 0px 0px 20px 0px !important;
        padding: 10px 0px !important;
    }

    .rest_save_button .rest {
        padding: 10px 0px !important;
    }
    .campaigner-modal .rest_save_button .rest{
        border-radius: 6px;
        margin: 0;
    }
    .campaigner-modal .rest_save_button .save{
        border-radius: 6px;
        margin: 0px 0px 5px 0px !important;
    }
    .campaigner-modal .rest_save_button{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
    }
}
@media (min-width:425px) and (max-width:767px) {
    .campaigner-modal .rest_save_button{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0;
    }
    .campaigner-modal .rest_save_button .rest{
        border-radius: 6px;
        padding: 05px 20px !important;
        margin: 0;
    }
    .campaigner-modal .rest_save_button .save{
        border-radius: 6px;
        padding: 05px 20px !important;
        margin: 0px 0px 0px 10px !important;
    }
  
}
@media (min-width:320px) and (max-width:900px) {
    .user_right {
        margin: 20px 0px 0px 0px;
    }

    .profile_menu_list .MuiAccordionSummary-content {
        font-size: 14px;
        line-height: 24px;
    }

    .profile_list ul li a {
        font-size: 14px;
        line-height: 24px;
    }

    .profile_list ul li img,
    .profile_menu_list .MuiAccordionSummary-content img,
    .logout button img {
        width: 17px;
        margin-right: 10px;
    }

    .logout button {
        font-size: 14px;
        line-height: 24px;
    }

    .logout {
        margin-top: 40px;
    }

    .relation_rashi_details_main .notes p {
        width: auto;
    }
}