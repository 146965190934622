.faqs{
    margin: 50px 0px 60px 0px;
}
.what_is_vedasankalpa h3{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0px 0px 05px 0px;
}
.what_is_vedasankalpa p{
    color: #2c0202e3 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    text-align: justify;
}
.main_faqs{
    margin-top: 40px;
}
.main_faqs .MuiAccordionSummary-root.Mui-expanded{
    min-height: 40px !important;
    padding-top: 10px
}
.main_faqs .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content{
    background: linear-gradient(276deg, #a92902 -4.36%, #e25704 49.89%, #f49004 105.28%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.main_faqs .MuiAccordionSummary-content{
    font-family: "Poppins", sans-serif;
    color: #2c0202;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 05px 0px !important;
}
.main_faqs .MuiAccordionDetails-root p{
    color: #2c0202e3 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    text-align: justify;   
}
.main_faqs .MuiAccordionDetails-root ul{
    margin: 0;
    padding: 05px 0px 05px 15px;
}
.main_faqs .MuiAccordionDetails-root ul li{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    text-align: justify;   
}
@media (min-width:320px) and (max-width:767px){
    .faqs{
        margin: 30px 0px 50px 0px;
    }
    .main_faqs .MuiAccordionSummary-content{
        font-size: 14px;
        line-height: 22px;
    }
    
}